import React from 'react'
import SimpleDropDown from "./SimpleDropDown";


export default function FrameRateSelection(props) {
    const rateList = [
        {label: "128", value: "128"},
        {label: "256", value: "256"},
        {label: "512", value: "512"},
        {label: "1024", value: "1024"},
        {label: "2048", value: "2048"},
        {label: "4096", value: "4096"},
        {label: "8192", value: "8192"},
        {label: "16384", value: "16384"},
    ];
    return SimpleDropDown(props, rateList);
}

