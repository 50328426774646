import React, {useEffect, useRef} from 'react'
import {ActivityIndicator, Text, View} from 'react-native'
import {batchDelete, batchLoad, batchRepeat, batchReset} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {useIsFocused, useNavigation} from "@react-navigation/core";
import style from './style'
import baseStyle, {COLOR_SECONDARY, COLOR_WHITE} from "../../baseStyle";
import {FAB} from "react-native-elements";
import InProgressBatchList from "./components/InProgressFileBatchList";
import BatchDeleteDialog from "./components/BatchDeleteDialog";
import BatchInfoDialog from "./components/BatchInfoDialog";
import CompletedBatchList from "./components/CompletedFileBatchList";

export default function HomeScreen() {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const loaded = useSelector((state) => state.batch.loaded);
    const isLoading = useSelector((state) => state.batch.loading);
    const inProgressBatch = useSelector((state) => state.batch.inProgress);
    const completedBatch = useSelector((state) => state.batch.completed);
    const isFocused = useIsFocused();
    const deleteRef = useRef();
    const infoRef = useRef();

    const onRepeat = async(batch)=>{
        dispatch(batchRepeat(batch));
        navigation.navigate('CreateBatch');
    }

    const startNewBatch = async()=>{
        await dispatch(batchReset());
        navigation.navigate('CreateBatch');
    };

    useEffect(async ()=>{
        if (!isLoading && isFocused){
            dispatch(batchLoad());
        }
    },[isFocused]);



    return (
        <View style={baseStyle.container}>
            <BatchDeleteDialog ref={deleteRef} onDelete={(batch)=>{dispatch(batchDelete(batch.id));}}/>
            <BatchInfoDialog ref={infoRef}/>

            <View style={style.batchContainer}>
                <Text style={style.batchHeader}>QUEUED JOBS</Text>

                {isLoading &&
                    <View style={style.loadingIndicator}>
                    <ActivityIndicator></ActivityIndicator>
                    </View>
                }

                { (loaded && inProgressBatch.length==0) &&
                    <View style={style.loadingIndicator}>
                        <Text style={style.emptyBatchText}>There are no jobs in the queue.</Text>
                    </View>
                }

                { (loaded && inProgressBatch.length> 0) &&
                    <InProgressBatchList
                        batches={inProgressBatch}
                        onInfo={(batch)=>{infoRef.current.open(batch);}}
                        onDelete={(batch)=>{deleteRef.current.open(batch);}}
                    />
                }
            </View>

            <View style={style.batchContainer}>
                <Text style={style.batchHeader}>COMPLETED JOBS</Text>

                {isLoading &&
                    <View style={style.loadingIndicator}>
                        <ActivityIndicator></ActivityIndicator>
                    </View>
                }

                { (loaded && completedBatch.length==0) &&
                    <View style={style.loadingIndicator}>
                    <Text style={style.emptyBatchText}>Your job has not yet been completed. Click the 'Plus' icon to create a new job.</Text>
                    </View>
                }

                { (loaded && completedBatch.length> 0) &&
                    <CompletedBatchList
                        batches={completedBatch}
                        onInfo={(batch)=>{infoRef.current.open(batch);}}
                        onDownload={(batch)=>{
                            const uri =
                            FileSystem.downloadAsync(remoteUri, FileSystem.documentDirectory + 'name.ext');
                        }}
                        onDelete={(batch)=>{deleteRef.current.open(batch);}}
                        onRepeat={(batch)=>onRepeat(batch)}
                    />
                }

            </View>

            <FAB
                onPress={startNewBatch}
                loading={isLoading}
                visible={!isLoading}
                color={COLOR_SECONDARY}
                placement='right'
                icon={{ name: 'add', color: COLOR_WHITE }}
                size="large"
            />
        </View>
    )
}

