import {
    AUTH_LOGIN_COMPLETE,
    AUTH_LOGIN_REQUESTED,
    AUTH_LOGOUT,
    AUTH_REFRESH_COMPLETE,
    AUTH_REFRESH_REQUESTED,
    AUTH_REQUEST_OTP,
    AUTH_RESET,
    AUTH_SET_EMAIL,
    AUTH_SET_LOGIN_TYPE,
    AUTH_SET_PHONE_NUMBER,
    AUTH_USER_REQUEST,
    AUTH_USER_REQUEST_COMPLETE
} from "../actionTypes";

const initialHistory = {
    useEmail: false,
    email: '',
    countryCode: '+1',
    phoneNumber: '',
    isLoading: false,
    isLoaded: false,
    isLoggingIn:false,
    isRefreshing:false,
    accessCode:undefined,
    refreshCode:undefined,
    expirationDate:0,
    state: undefined,
    user: undefined,
    isUserLoading:false,
    isUserLoaded:false


}

export const auth = (state = JSON.parse(JSON.stringify(initialHistory)), action = {}) => {
    switch (action.type) {

        case AUTH_REQUEST_OTP:{
            return{
                ...state,
                state: undefined
            }

        }
        case AUTH_RESET:{
            return{
                ...initialHistory
            }
        }

        case AUTH_LOGOUT: {
            return {
                ...state,
                useEmail: false,
                email:'',
                countryCode: '+1',
                phoneNumber:'',
                accessCode:'',
                refreshCode:'',
                expirationDate:'',
                state:undefined,
                user: undefined,
                isUserLoading:false,
                isUserLoaded:true
            }
        }

        case AUTH_SET_LOGIN_TYPE: {
            return {
                ...state,
                useEmail: action.value,
                state: undefined
            }
        }

        case AUTH_SET_EMAIL: {
            return {
                ...state,
                useEmail: true,
                email: action.value,
                state: undefined
            }
        }
        case AUTH_SET_PHONE_NUMBER: {
            return {
                ...state,
                useEmail: false,
                countryCode: action.countryCode,
                phoneNumber: action.phoneNumber,
                state: undefined
            }
        }

        case AUTH_LOGIN_REQUESTED: {
            return {
                ...state,
                accessCode: '',
                refreshCode: '',
                expirationDate:0,
                isLoggingIn: true,
                state: undefined
            }
        }

        case AUTH_LOGIN_COMPLETE: {
            return {
                ...state,
                accessCode: action.accessCode,
                refreshCode: action.refreshCode,
                expirationDate:action.expirationDate,
                isLoggingIn: false,
                state: action.state,
                isRefreshing: false
            }
        }

        case AUTH_REFRESH_REQUESTED: {
            return {
                ...state,
                accessCode: '',
                expirationDate:0,
                isRefreshing: true
            }
        }


        case AUTH_REFRESH_COMPLETE: {
            return {
                ...state,
                accessCode: action.accessCode,
                refreshCode: action.refreshCode,
                expirationDate:action.expirationDate,
                isRefreshing: false
            }
        }

        case AUTH_USER_REQUEST:{
            return {
                ...state,
                isUserLoaded: false,
                isUserLoading: true
            }
        }

        case AUTH_USER_REQUEST_COMPLETE:{
            return {
                ...state,
                user: action.user,
                isUserLoaded: action.user !== undefined,
                isUserLoading: false
            }
        }

        default:
            return state
    }
}

