import React from 'react'
import {Text, TouchableOpacity, View} from 'react-native'
import Constants from 'expo-constants'
import {useDispatch, useSelector} from 'react-redux'
import style from './style'
import {authLogout} from "../store/actions";

export default function DrawerContent(props) {
    const dispatch = useDispatch();
    const navigation = props.navigation;
    const {navigate} = navigation;
    const user = useSelector((state) => state.auth.user);
    const isAdmin = (user !== undefined) && (user.roles.includes('ADMIN'))

    const logoutHandler = async () => {
        console.log('[DrawerContent] logout()')
        await dispatch(authLogout())
        navigate('Landing', {reset: true})
    }

    return (
        <View style={style.container}>

            <View style={style.menuItems}>
                {/*<DrawerItemList {...props} />*/}

                    <TouchableOpacity onPress={() => navigation.navigate('Home')}>
                        <Text style={style.item}>{'Home'}</Text>
                    </TouchableOpacity>
                {isAdmin &&
                    <TouchableOpacity onPress={() => navigation.navigate('Users')}>
                        <Text style={style.item}>{'Users'}</Text>
                    </TouchableOpacity>
                }


                <TouchableOpacity onPress={() => logoutHandler()}>
                        <Text style={style.item}>{'Log out'}</Text>
                    </TouchableOpacity>
            </View>

            <View style={style.menuItems}>
                <View style={style.infoContainer}>
                    <Text style={style.itemTitle}>{'Info'}</Text>
                    <Text>{`Version: ${Constants.manifest.version}`}</Text>
                </View>
            </View>
        </View>
    )
}
