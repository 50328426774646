import React from 'react'
import {StyleSheet, Text, View} from "react-native";


import {COLOR_BLACK, COLOR_PRIMARY, FONT_FAMILY, FONT_SIZE_BODY, FONT_SIZE_CAPTION} from "../../../baseStyle";
import LabeledProgressBar from "../../../components/LabeledProgressBar";
import {humanFileSize} from "../../../store/formatUtil";


export default function UploadedFileEntry(props) {




    const file = props.file;
    const progress = file.bytesUploaded / file.fileObject.size;
    const fileName = (file.model?'MODEL:':'SOURCE:') + file.name + ' (' + humanFileSize(file.fileObject.size, true, 2) + ')';

    return (
        <View style={style.container}>
            <Text style={style.fileNameText}>{fileName}</Text>


            {file.status==='QUEUED' &&
                <Text style={style.queuedText}>QUEUED</Text>
            }

            {file.status==='HASHING' &&
                <Text style={style.queuedText}>HASHING</Text>
            }


            {file.status==='COMPLETE' &&
                <View style={style.completeContainer}>
                    <Text style={style.completeText}>COMPLETE</Text>
                    {/*<Text style={style.completeMD5Text}>MD5: {file.md5}</Text>*/}
                </View>
            }

            {file.status==='UPLOADING' &&
                <LabeledProgressBar progress={progress}/>
            }

        </View>
    )
}


const style = StyleSheet.create({
    container:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignContent:'center',
        alignItems:'center',
        padding:8,
        paddingLeft:16,
        margin:4
    },
    fileNameText:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_BODY,
        color: COLOR_PRIMARY
    },
    queuedText:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_BODY,
        color: COLOR_BLACK
    },
    completeContainer:{
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    completeText:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_BODY,
        color: '#209920'
    },

    completeMD5Text:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_CAPTION,
        color: '#209920'
    },
    progressContainer:{
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
});
