import React, {forwardRef, useImperativeHandle, useState} from 'react'
import baseStyle, {COLOR_GREY_DARK, COLOR_RED, FONT_FAMILY, FONT_SIZE_CAPTION} from "../../../baseStyle";
import {StyleSheet, Text} from "react-native";
import {Dialog, Portal} from "react-native-paper";
import {Button} from "react-native-elements";
import {paperTheme} from "../../../paperTheme";


const BatchDeleteDialog = (props, ref) =>{
    const [dialogOptions, setDialogOptions] = useState({
        batch:{},
        dateString:'',
        visible:false
    });

    const open = (batch) => {
        setDialogOptions({
            batch:batch,
            dateString:  new Date(batch.createdEpochMs).toLocaleString(),
            visible:true
        })
    }

    const close = () => {
        setDialogOptions({
            ...dialogOptions,
            visible:false
        })
    }

    useImperativeHandle(ref, () => ({
        // methods connected to `ref`
        open: (batch) => { open(batch);},
        close: () => { close(); }
    }))


    return (
        <Portal >
            <Dialog theme={paperTheme} style={[{ maxWidth: 800, alignSelf: "center" }]} visible={dialogOptions.visible}>
                <Dialog.Title>Confirm Delete</Dialog.Title>
                <Dialog.Content >
                    <Text style={baseStyle.dialogText}>Are you sure you would like to delete this job?</Text>
                    <Text style={[style.dialogText,{marginTop:16}]}>Description: {dialogOptions.batch.description}</Text>
                    <Text style={style.dialogText}>Created Date: {dialogOptions.dateString}</Text>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button
                        type={'clear'}
                        titleStyle={baseStyle.clearButtonText}
                        onPress={()=>{
                            close();
                        }}
                        title='CANCEL'/>

                    <Button
                        type={'clear'}
                        titleStyle={[baseStyle.clearButtonText,{color:COLOR_RED}]}
                        onPress={()=>{
                            close();
                            props.onDelete(dialogOptions.batch);
                        }}
                        title='DELETE'/>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}



const style = StyleSheet.create({
    dialogText:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_CAPTION,
        color: COLOR_GREY_DARK,
    }
});


export default forwardRef(BatchDeleteDialog);
