import React, {useEffect} from 'react'
import {SafeAreaView, ScrollView, Text, View} from 'react-native'
import {useDispatch, useSelector} from "react-redux";
import {useNavigation} from "@react-navigation/core";
import baseStyle from "../../baseStyle";
import BatchEntryStatus from "./components/BatchEntryStatus";
import UploadedFileList from "./components/UploadedFileList";


export default function FilesUploadingScreen() {
    const dispatch = useDispatch();
    const navigation = useNavigation();

    const {
        uploadFileList,
        uploaded,
        uploading,
    } = useSelector((state) => state.batch);


    const {
        isRepeat,
        fileType,
        numChannels,
    } = useSelector((state) => state.batch.data);


    useEffect(() => {
        if (!uploading && uploaded){
            navigation.navigate('UploadComplete');
        }
    }, [uploaded, uploading]);


    return (

        <ScrollView>


            <SafeAreaView style={[baseStyle.container, {alignItems: 'center'}]}>

                <BatchEntryStatus isRepeat={isRepeat} step={3}/>

                <View style={baseStyle.raisedCard}>

                    <View>
                        <Text style={baseStyle.titleStyle}>Files Uploading </Text>
                        <Text style={baseStyle.instructions}>Leave this page open and in the foreground while the files are being uploaded. If
                            this page is closed before the file upload is complete, the job will be cancelled.</Text>
                    </View>


                    <UploadedFileList
                        files={uploadFileList}
                        numChannels={numChannels}
                        fileType={fileType}
                    />


                </View>

            </SafeAreaView>
        </ScrollView>

    )
}

