import React from 'react'
import Steps from "../../../components/Steps";


export default function BatchEntryStatus(props) {

    const isRepeat= props.isRepeat

    const steps = ['Set Parameters', 'Select Source Files', 'Select Model Files', 'Upload Files', 'Complete']
    const repeatSteps = ['Set Parameters', 'Complete']

    return (
        <Steps
            step={props.step}
            steps={isRepeat?repeatSteps:steps}
            onSelected={(value)=> {
                if (props.onSelected) props.onSelected(value);
            }}
        />
    )
}

