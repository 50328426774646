import {
  USER_UPDATE,
  USER_UPDATE_COMPLETE,
  USERS_LOAD_COMPLETE,
  USERS_LOAD_REQUESTED, USERS_RESET
} from "../actionTypes/users";


const initialHistory = {
  loading:false,
  loaded: false,
  list:[],
  updating: false,
  updated: false

}


export const users = (state = JSON.parse(JSON.stringify(initialHistory)), action = {}) => {
  switch (action.type) {

    case USERS_LOAD_REQUESTED:{
      return {
        ...state,
        loaded: false,
        loading:true,
        updated: false,
        updating: false
      }
    }
    case USERS_RESET:{
      return {
        ...state,
        list:[],
        loaded: false,
        loading:false,
        users: undefined,
        updated: false,
        updating: false
      }
    }

    case USERS_LOAD_COMPLETE:{
      return {
        ...state,
        list: action.users,
        loaded: true,
        loading:false,
        updated: false,
        updating: false
      }
    }

    case USER_UPDATE:{
      return {
        ...state,
        updated: false,
        updating: true
      }
    }

    case USER_UPDATE_COMPLETE:{

      return {
        ...state,
        loaded: false, //Force reload
        updated: true,
        updating: false
      }
    }

    default:
      return state
  }


}

