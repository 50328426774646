import {APP_SET_TIMER_PUMP_HANDLE, APP_START_TIMER, APP_STOP_TIMER, AUTH_STATE_AUTHENTICATED} from "../actionTypes";
import {authLogout, authRefreshTokenIfExpired} from "./auth";
import {batchUploadTask, checkBatchReload} from "./batch";

const APP_TIMER_INTERVAL = 250;

export const appLogout = () => async(dispatch) =>{
    await dispatch(authLogout());
}

const appTimerHandle = () => async (dispatch, getState) => {
    dispatch(batchUploadTask());

    if (getState().auth.state === AUTH_STATE_AUTHENTICATED) {
        await dispatch(authRefreshTokenIfExpired());
        dispatch(checkBatchReload());
    }
}

const scheduleNextTimer = () => async (dispatch, getState) => {
    const oldHandle = getState().app.timerPumpIntervalHandle;
    if (oldHandle !== undefined) clearTimeout(oldHandle);
    const useFastPost = getState().batch.uploading;
    const interval = useFastPost?5:APP_TIMER_INTERVAL;
    const newHandle = setTimeout(async()=>{
        await dispatch(appTimerHandle());
        if (getState().app.isRunning) {
            await dispatch(scheduleNextTimer());
        }
    }, interval);

    dispatch({type:APP_SET_TIMER_PUMP_HANDLE, value:newHandle});
}

export const appStartTimerPumpInterval = () => async (dispatch, getState) => {
    console.log('[actions/app][appStartTimerPumpInterval] Creating a new interval timer');
    await dispatch({type: APP_START_TIMER});
    await dispatch(scheduleNextTimer());
}

export const appStopTimerPumpInterval = () => async (dispatch, getState) => {
    console.log('[actions/app][appStopTimerPumpInterval] Clearing old interval timer');
    await dispatch({type:APP_STOP_TIMER});
    const oldHandle = getState().app.timerPumpIntervalHandle;
    if (oldHandle !== undefined) clearTimeout(oldHandle);
}
