import React from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {FAB} from "react-native-elements";
import {
    COLOR_GREY_DARK,
    COLOR_GREY_LIGHTER,
    COLOR_PRIMARY,
    COLOR_SECONDARY,
    COLOR_WHITE,
    FONT_FAMILY,
    FONT_SIZE_CAPTION
} from "../baseStyle";

const displayStepName=(text, color) =>{
    return (
        <View style={[style.displayBox]}>
            <View style={[style.displayDash, {backgroundColor:color}]}></View>
            <Text style={[style.displayText, {color:color}]}>{text}</Text>
            <View style={[style.displayDash, {backgroundColor:color}]}></View>
        </View>
    )
}

const displayStep=(item, index, props)=>{
    const selectedStep = props.step;

    const getColor=(step, selectedStep) => {
        if (step==selectedStep) return COLOR_SECONDARY;
        else if (step > selectedStep) return COLOR_GREY_DARK;
        else return COLOR_PRIMARY;

    }

    const getIcon=(step, selectedStep) => {
        if (step < selectedStep){
            return {color: COLOR_WHITE,
                type:'material-community',
                name:'check'
            }
        } else {
            return {color: COLOR_WHITE,
                type:'material-community',
                name:'numeric-' + (1+step).toString()
            }
        }
    }

    return (
        <View style={style.stepContainer} key={index}>
            <FAB
                onPress={()=>{
                    if (props.onSelected) props.onSelected(index)
                }}
                color={getColor(index, selectedStep)}
                size={'small'}
                icon={getIcon(index, selectedStep)} />
            {displayStepName(item, getColor(index, selectedStep))}
        </View>
    )
}

export default function Steps(props) {
    return (
        <View style={style.container}>
            {props.steps.map((item,index)=>displayStep(item, index, props))}
        </View>
    )
}


const style = StyleSheet.create({
    container:{
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center',
        margin:'auto'
    },
    displayBox:{
        flexDirection:"row",
        justifyContent: 'center',
        alignContent:'center',
        alignItems: 'center',
        margin:16,
    },
    displayText:{
        fontFamily:FONT_FAMILY,
        fontSize:FONT_SIZE_CAPTION,
        color:COLOR_GREY_DARK
    },
    displayDash:{
        height:2,
        width:32,
        backgroundColor: COLOR_GREY_LIGHTER,
        marginLeft:8,
        marginRight:8,
    },
    stepContainer:{
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center'
    }

});

