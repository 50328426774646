import {createStackNavigator} from '@react-navigation/stack'
import React from 'react';

//Sub Navigation
import {useDispatch, useSelector} from "react-redux";
import AuthNavigator from "./AuthNavigator";
import AppNavigator from "./AppNavigator";
import {AUTH_STATE_AUTHENTICATED} from "../store/actionTypes";

const Stack = createStackNavigator();



/**
 * Top level navigator. Handles the auth flow between the authenticated and un-auntenticated stacks
 * @return {JSX.Element}
 * @constructor
 */
export default function RootNavigator() {

    const isLoading = useSelector((state) => state.auth.isLoading);
    const isLoaded = useSelector((state) => state.auth.isLoaded);
    const authState = useSelector((state) => state.auth.state);
    const isAuthenticated = authState === AUTH_STATE_AUTHENTICATED;
    const dispatch = useDispatch();

    console.debug(`[RootNavigator] Normal Render isAuthenticated: ${isAuthenticated}`);
    return (
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                    headerMode: 'screen',
                    gestureEnabled: false
                }}
            >
                {!isAuthenticated ? (
                    <Stack.Screen options={{headerShown: false}} name="Auth" component={AuthNavigator}/>
                ) : (
                    <Stack.Screen options={{headerShown: false}} name="App" component={AppNavigator}/>
                )}
            </Stack.Navigator>
    );
}

