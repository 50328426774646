import React, {useEffect, useRef} from 'react'
import {ActivityIndicator, FlatList, Text, View} from 'react-native'
import {userAdd, usersLoad} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {useIsFocused, useNavigation} from "@react-navigation/core";
import style from './style'
import baseStyle, {COLOR_SECONDARY, COLOR_WHITE} from "../../baseStyle";
import {FAB} from "react-native-elements";
import UserAddDialog from "./components/UserAddDialog";

export default function UsersScreen() {
    const dispatch = useDispatch();
    const navigation = useNavigation();

    const loaded = useSelector((state) => state.users.loaded);
    const isLoading = useSelector((state) => state.users.loading);
    const users = useSelector((state) => state.users);
    const list = users.list;
    const isFocused = useIsFocused();
    const addRef = useRef();


    const addNewUser = async()=>{
        addRef.current.open();
    };

    useEffect(async ()=>{
        if (!isLoading && isFocused){
            dispatch(usersLoad());
        }
    },[isFocused]);


    const renderUser=(u)=>{
        return (
            <View><Text>{u.item.email}</Text></View>
        )
    }

    return (
        <View style={baseStyle.container}>
            <UserAddDialog ref={addRef} onAdd={(email, phoneNumber)=>{dispatch(userAdd(email, phoneNumber));}}/>


            <View style={style.batchContainer}>
                <Text style={style.batchHeader}>Users</Text>

                {isLoading &&
                    <View style={style.loadingIndicator}>
                    <ActivityIndicator></ActivityIndicator>
                    </View>
                }


                { (loaded) &&
                    <FlatList data={list} renderItem={renderUser}>
                    </FlatList>
                }
            </View>


            <FAB
                onPress={addNewUser}
                loading={isLoading}
                visible={!isLoading}
                color={COLOR_SECONDARY}
                placement='right'
                icon={{ name: 'add', color: COLOR_WHITE }}
                size="large"
            />
        </View>
    )
}

