import React from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {
    COLOR_PRIMARY,
    COLOR_PRIMARY_DARK,
    COLOR_PRIMARY_LIGHTER,
    COLOR_WHITE,
    FONT_FAMILY,
    FONT_SIZE_CAPTION
} from "../baseStyle";
import * as Progress from 'react-native-progress';


export default function LabeledProgressBar(props) {
    const progress = props.progress?props.progress:0;
    const borderColor = props.color?props.color:COLOR_PRIMARY_DARK;
    const color = props.color?props.color:COLOR_PRIMARY;
    const unfilledColor = props.color?props.color:COLOR_PRIMARY_LIGHTER;

    return (
        <View style={style.progressContainer}>
            <Progress.Bar color={color}
                          useNativeDriver={true}
                          borderColor={borderColor}
                          unfilledColor={unfilledColor}
                          progress={progress} width={300} height={24}/>

            <View style={style.progressLabelContainer}>
                <Text style={style.progressLabel}>{(progress * 100).toFixed(1)} % </Text>
            </View>
        </View>
    )
}



const style = StyleSheet.create({
    progressContainer:{
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    progressLabelContainer:{
        position: "absolute",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        height:24,
        paddingTop:2
    },
    progressLabel: {
        color: COLOR_WHITE,
        fontFamily:FONT_FAMILY,
        fontSize:FONT_SIZE_CAPTION
    }
});
