import React from 'react'
import {ScrollView, StyleSheet, Text, View} from "react-native";
import UploadedFileEntry from "./UploadedFileEntry";
import baseStyle from "../../../baseStyle";

const getUploadedCount = (files) => {
    let count = 0;
    for (let file of files){
        if (file.status==='COMPLETE') count++;
    }
    return count;
}
export default function UploadedFileList(props) {
    const {files, numChannels,fileType} = props;
    const uploadedFiles = getUploadedCount(files);
    const uploadedFilesString = `UPLOADED ${uploadedFiles} of ${files.length} FILES`;

    return (
        <View style={style.container}>
            <Text style={[baseStyle.subheadStyle, {marginTop:0, marginBottom:0}]}>{uploadedFilesString}</Text>
            <ScrollView>
                {files.map((file, index)=>{
                    return <UploadedFileEntry key={index} file={file}/>
                })}
            </ScrollView>
        </View>

    )
}



const style = StyleSheet.create({
    container:{
        maxHeight:300,
        marginBottom:32
    }
});
