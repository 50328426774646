import React from 'react'
import baseStyle, {COLOR_SECONDARY} from "../../../baseStyle";
import {TextInput} from "react-native-paper";
import {Text, View} from "react-native";
import {paperTheme} from "../../../paperTheme";


export default function NumericInput(props) {
    const decimal = props.decimal;

    const formatText = (value, decimal) =>{
        if (value !== undefined && value!=null) {
            let result = value.toString();
            //const numZeros = result.length - result.indexOf('.');
            // for (let i=numZeros; i < decimal; i++){
            //     result += '0';
            // }
            return result;
        } else return '';
        // if (value !== undefined && value!=null)  {
        //     if (decimal === undefined || decimal === 0){
        //         return value.toString();
        //     } else {
        //         if (value.toString()==='0') return '0.0';
        //         else return value.toString();
        //
        //     }
        // }
        // else return '';

    }


    const onChangeText = (value) => {

            if (decimal === undefined || decimal === 0) {
                value = value.replace(/[^0-9]/g, '');
                if (props.onChangeText) props.onChangeText(value);
            } else {
                const validNumber = new RegExp(/^\d*\.?\d*$/);
                if (props.onChangeText && validNumber.test(value)) props.onChangeText(value);
            }

    }

    return (
        <View style={baseStyle.subcaptionContainerStyle}>
            <TextInput
                theme={paperTheme}
                activeOutlineColor={COLOR_SECONDARY}
                style={[props.style, {marginBotton:0, width:300}]}
                label={props.label}
                mode='outlined'
                value={formatText(props.value, decimal)}
                onChangeText={onChangeText}
                right={props.right}
            />
            <Text style={baseStyle.subcaptionText}>{props.caption}</Text>
        </View>
    )
}

