import React, {useState} from 'react'
import {Image, ImageBackground, SafeAreaView, ScrollView, Text, View} from 'react-native'
import {useDispatch, useSelector} from "react-redux";
import baseStyle, {COLOR_PRIMARY} from "../../baseStyle";
import style from "./style";
import authScreenStyle from "./style";
import OtpCodeInput from "./components/OtpCodeInput";
import {authLogin, authRequestOneTimePassword} from "../../store/actions";
import {AUTH_STATE_FAILED} from "../../store/actionTypes";
import LogoSVG from "../../svg/logo";
import {Button} from "react-native-elements";


export default function OneTimePasswordScreen() {
    const dispatch = useDispatch();
    const email = useSelector((state) => state.auth.email);
    const countryCode = useSelector((state) => state.auth.countryCode);
    const phoneNumber = useSelector((state) => state.auth.phoneNumber);
    const useEmail = useSelector((state) => state.auth.useEmail);
    const username = useEmail ? email : countryCode + phoneNumber;
    const [value, setValue] = useState('');
    const authState = useSelector((state) => state.auth.state);
    const hasError = AUTH_STATE_FAILED === authState;

    return (
        <ImageBackground resizeMode="cover"
                         style={{height: '100%', width: '100%'}}
                         source={require('../../../assets/teaser-bg-restoringHearing.png')}>

            <SafeAreaView style={style.container}>
                <ScrollView style={baseStyle.containerScrollView}>

                <Image
                    style={style.logoImage}
                    source={require('../../../assets/Wave_Sciences_Logo.png')}
                />

                <View style={[style.centeredBox, {margin: 'auto'}]}>


                    <LogoSVG height={70} color={COLOR_PRIMARY} style={style.logoImage}/>


                    {hasError ?
                        <Text style={style.error}>An invalid code was entered. Please try again.</Text>:
                        <Text style={style.error}>&nbsp;</Text>
                    }

                    <Text style={[authScreenStyle.instructions, {textAlign: 'center', marginBottom:24}]}>Please enter the 6-digit
                        code that was sent to {username}. </Text>

                    <OtpCodeInput value={value} onChange={(value) => setValue(value)}/>

                    <View style={[{marginTop: 16}]}/>
                    <Button
                        title='Sign In'
                        buttonStyle={baseStyle.raisedButton}
                        titleStyle={baseStyle.raisedButtonText}
                        onPress={() => {
                            if (value.length == 6){
                                console.log('LOGGING IN ' + username);
                                dispatch(authLogin(username, value));
                            }
                        }}
                    />



                    <View style={[style.orBox, {marginTop: 32}]}>
                        <Text style={style.orText}>Didn't receive a code?</Text>
                    </View>

                    <Button
                        type='clear'
                        title={'Resend Code'}
                        titleStyle={[baseStyle.clearButtonText, style.orButtonText]}
                        onPress={() => {
                            const username =  useEmail?email:countryCode+phoneNumber;
                            dispatch(authRequestOneTimePassword(username));
                        }}
                    />

                </View>

                </ScrollView>
            </SafeAreaView>
        </ImageBackground>
    )
}

