import React from 'react'
import {StyleSheet, Text, View} from "react-native";

import {COLOR_GREY_DARK, COLOR_GREY_LIGHT, COLOR_GREY_LIGHTER, FONT_FAMILY, FONT_SIZE_BODY} from "../../../baseStyle";
import {FAB} from "react-native-elements";
import {humanFileSize} from "../../../store/formatUtil";


export default function SelectedFileListEntry(props) {
    const isRepeat = props.isRepeat;
    const file = props.file;


    if (!isRepeat && (file.name !== undefined)) {
        const fileString = file.name + ' (' + humanFileSize(file.fileObject.size, true, 2) + ')';
        return (
            <View style={style.container}>
                <Text style={style.text}>{fileString}</Text>
                <FAB
                    onPress={() => {
                        if (props.onDelete) props.onDelete(file)
                    }}
                    icon={{name: 'close', color: 'white'}}
                    color={COLOR_GREY_DARK}
                    size='small'></FAB>
            </View>
        )
    } else if (file.fileName !== undefined){

        const fileString = file.fileName + ' (' + humanFileSize(file.fileSize, true, 2) + ')';
        return (
            <View style={style.container}>
                <Text style={style.text}>{fileString}</Text>
            </View>
        )
    } else {
        return (<View/>);
    }
}


const style = StyleSheet.create({
    container:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        padding:8,
        paddingLeft:16,
        backgroundColor:COLOR_GREY_LIGHTER,
        borderRadius:32,
        borderStyle:'solid',
        borderColor: COLOR_GREY_LIGHT,
        borderWidth:1,
        margin:4
    },
    text:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_BODY,
        color: COLOR_GREY_DARK
    }
});
