import React from 'react';
import {StyleSheet, Text, TextInput, View} from 'react-native'
import authScreenStyle from "../style";
import {COLOR_PRIMARY, FONT_FAMILY, FONT_SIZE_BODY} from "../../../baseStyle";

const style = StyleSheet.create({
    borderedTextInput:{
        borderColor: COLOR_PRIMARY,
        borderStyle: 'solid',
        borderWidth:'1',
        backgroundColor:'#EAEAEA',
        borderRadius: 8,
        margin:8,
        padding:16,
        fontFamily:FONT_FAMILY,
        fontSize:FONT_SIZE_BODY,
        outlineStyle: 'none'
    },
});

export default function EmailAddressInput(props) {

    return (
        <View style={style.container}>
            <Text style={[authScreenStyle.instructions,{textAlign:'center'}]}>Please enter the email address for your account below</Text>

            <TextInput
                style={style.borderedTextInput}
                autoComplete='email'
                autoFocus={true}
                enablesReturnKeyAutomatically={true}
                onChangeText={(value) => props.onChanged(value)}
                placeholder="Email address"
                keyboardType="email-address"
                value={props.value}
                autoCapitalize="none"
                textContentType="emailAddress"
                autoCorrect={false}
                />
        </View>

    );
}

