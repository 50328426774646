import React from 'react'
import {StyleSheet, Text, View} from "react-native";

import {
    COLOR_BLACK,
    COLOR_GREY_DARK,
    COLOR_GREY_LIGHT,
    COLOR_RED,
    COLOR_SECONDARY,
    COLOR_SECONDARY_LIGHT,
    COLOR_WHITE,
    FONT_FAMILY,
    FONT_SIZE_BODY,
    FONT_SIZE_CAPTION
} from "../../../baseStyle";
import {Button} from "react-native-elements";
import {formatDate} from "../../../store/formatUtil";
import BatchDownloadIcon from "./BatchDownloadIcon";

export default function CompletedFileEntry(props) {
    const batch = props.batch;
    const dateString = formatDate(batch.createdEpochMs);
    const completedDateString = formatDate(batch.lastUpdatedEpochMs);



    return (

        <View style={style.container}>

            <Text style={style.entryText}>{batch.description}</Text>
            <Text style={style.entryText}>{dateString}</Text>
            <Text style={style.entryText}>{completedDateString}</Text>
            <Text style={style.entryText} >{batch.status}</Text>
            <View style={style.entryButtonContainer}>
                <View style={style.entryButtons}>
                    <Button
                        onPress={()=>{
                            props.onInfo(batch);
                        }}
                        icon={{name:'info', type:'font-awesome', size:24, color:'white'}}
                        buttonStyle={style.raisedButton}/>

                    <BatchDownloadIcon batchId={batch.id}/>

                    <Button
                        onPress={()=>{
                            props.onRepeat(batch);
                        }}
                        icon={{name:'retweet', type:'font-awesome', size:24,  color:'white'}}
                        buttonStyle={[style.raisedButton, {backgroundColor: COLOR_SECONDARY_LIGHT}]}/>


                    <Button
                        onPress={()=>{
                            props.onDelete(batch);
                        }}
                        icon={{name:'trash', type:'font-awesome', size:24,  color:'white'}}
                        buttonStyle={[style.raisedButton, {backgroundColor: COLOR_RED}]}/>



                </View>
            </View>
        </View>
    )
}


const style = StyleSheet.create({
    container:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        padding:8,
        paddingLeft:16,
        backgroundColor:COLOR_WHITE,
        borderRadius:8,
        borderStyle:'solid',
        borderColor: COLOR_GREY_LIGHT,
        borderWidth:1,
        margin:4
    },
    entryText:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_BODY,
        color: COLOR_BLACK,
        flex:1,
        textAlignVertical:'top',
        maxWidth:'25%',
        padding:16,
        paddingBottom:0,
        paddingTop:0,
        height:'100%'
    },
    entryButtonContainer:{
      flex:1,
    },
    entryButtons:{
        flexDirection:'row',
        justifyContent:'space-around',
        alignItems:'center',

        flex:1,
        maxWidth: 150
    },

    raisedButton:{
        backgroundColor: COLOR_SECONDARY,
        alignItems:'center',
        justifyContent:'center',
        width:36,
        height:36,
        borderRadius:18,
    },
    dialogText:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_CAPTION,
        color: COLOR_GREY_DARK,
    }


});
