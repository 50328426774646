import React, {useMemo, useState} from 'react'
import DropDown from "react-native-paper-dropdown";
import baseStyle, {COLOR_SECONDARY} from "../../../baseStyle";
import {Text, View} from "react-native";
import {paperTheme} from "../../../paperTheme";


export default function SimpleDropDown(props, rateList) {
    const [showDropDown, setShowDropDown] = useState(false);

    const {label,
          minRate} = props;

    const value = props.value.toString();

    const onSelected = (value) => {
        if (props.onSelected) {
            props.onSelected(value);
        }
    }

    useMemo(()=>{
        if (value !== undefined && parseInt(value) < parseInt(minRate)){
            setTimeout(()=>{props.onSelected(minRate);}, 500);
        }
    }, [value, minRate])



    const getAllowedValues = (minRate, valueArray) => {
        const allowedValues = [];
        for (let v of valueArray){
            if (parseInt(v.value) >= parseInt(minRate)){
                allowedValues.push(v);
            }
        }
        return allowedValues;
    }

    return (
        <View style={baseStyle.subcaptionContainerStyle}>
            <DropDown
                dropDownItemSelectedStyle={{backgroundColor:'#FFF'}}
                dropDownItemStyle={{backgroundColor:'#FFF'}}

                theme={paperTheme}
                inputProps={{style: props.style}}
                activeColor={COLOR_SECONDARY}
                label={label}
                mode={"outlined"}
                visible={showDropDown}
                showDropDown={() => setShowDropDown(true)}
                onDismiss={() => setShowDropDown(false)}
                value={value}
                setValue={onSelected}
                list={getAllowedValues(minRate, rateList)}
            />
            <Text style={baseStyle.subcaptionText}>{props.caption}</Text>
        </View>
    )

}

