import React, {useEffect, useState} from 'react'
import DropDown from "react-native-paper-dropdown";
import baseStyle, {COLOR_SECONDARY} from "../../../baseStyle";
import {Text, View} from "react-native";
import {paperTheme} from "../../../paperTheme";


export default function SampleRateSelection(props) {
    const [showDropDown, setShowDropDown] = useState(false);

    const value = props.value.toString();

    const onSelected=(value)=>{
        if (props.onSelected){
            const regex = /[+-]?\d+(\.\d+)?/g;
            const floatString = value.match(regex)[0];
            // const floatValue = parseFloat(floatString);
            props.onSelected(floatString);
        }
    }
    const caption='File sample rate in Hz.'
    const binList = [
        {label: "24000", value: "24000",},
        {label: "48000", value: "48000"},
    ];

    const int16List = [
        {label: "16537.5", value: "16537.5",},
    ];
    const wavList = [
        {label: "8000", value: "8000",},
        {label: "11025", value: "11025",},
        {label: "12000", value: "12000",},
        {label: "16000", value: "16000",},
        {label: "22050", value: "22050",},
        {label: "24000", value: "24000",},
        {label: "44100", value: "44100",},
        {label: "48000", value: "48000",},
        {label: "96000", value: "96000",},
        {label: "192000", value: "192000",},

    ];
    const inList=(list, value)=>{
        for (let x of list){
            if (value===x.value) return true;
        }
        return false;
    }

    useEffect(()=>{



        switch (props.fileType){
            case 'BIN':{
                if (!inList(binList, value)){
                    if (value !== binList[0].value)  onSelected(binList[0].value);
                }
                break;
            }
            case 'INT16':{
                if (!inList(int16List, value)){
                    if (value !== int16List[0].value) onSelected(int16List[0].value);
                }
                break;
            }
            default:{
                if (!inList(wavList, value)){
                    if (value !== wavList[0].value) onSelected(wavList[0].value);
                }
            }
        }
    }, [props.fileType, value]);


    const getOptions = (fileType) => {
        switch (fileType){
            case 'BIN': return binList;
            case 'INT16': return int16List;
            case 'WAV': return wavList;
            default: return otherList;
        }
    }


    return (
        <View style={baseStyle.subcaptionContainerStyle}>
            <DropDown
                dropDownItemSelectedStyle={{backgroundColor:'#FFF'}}
                dropDownItemStyle={{backgroundColor:'#FFF'}}
                theme={paperTheme}
                inputProps={{style: [props.style]}}
                activeColor={COLOR_SECONDARY}
                label={"Sample Rate"}
                mode={"outlined"}
                visible={showDropDown}
                showDropDown={() => setShowDropDown(true)}
                onDismiss={() => setShowDropDown(false)}
                value={value}
                setValue={props.onSelected}
                list={getOptions(props.fileType)}
            />
            <Text style={baseStyle.subcaptionText}>{caption}</Text>
        </View>
    )


}

