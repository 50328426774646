const ENV = {
  dev: {
    serverUrl: 'http://10.0.0.17:5000',
    websocketUrl: 'ws://10.0.0.17:5000/ws',
  },
  devMtP: {
    serverUrl: 'http://192.168.1.164:5000',
    websocketUrl: 'ws://192.168.1.164:5000/ws',
  },
  prod: {
    serverUrl: 'https://glimpseweb.roclp.com',
    websocketUrl: 'wss://glimpseweb.roclp.com/ws',

  },
};

export const getEnvVars = () => {
  // What is __DEV__ ?
  // This variable is set to true when react-native is running in Dev mode.
  // __DEV__ is true when run locally, but false when published.
  if (__DEV__) {
    console.info('USING DEV PARAMETERS: ', ENV.devMtP);
    return ENV.devMtP;
  } else {
    console.info('USING PROD PARAMETERS: ', ENV.prod);
    return ENV.prod;
  }
};
