import {StyleSheet} from 'react-native'
import {COLOR_GREY_DARKER, COLOR_SECONDARY, FONT_FAMILY, FONT_SIZE_CAPTION, FONT_SIZE_HEADING} from "../../baseStyle";

export default StyleSheet.create({
    batchContainer:{
        flex:1,
        paddingLeft:16,
        paddingRight:16,
        paddingTop:8,
        paddingBottom:8,
        flexDirection:"column",
        justifyContent:"start",
        alignItems:"start",
        alignContent:"start"
    },

    batchHeader:{
        color: COLOR_GREY_DARKER,
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_CAPTION
    },
    loadingIndicator:{
        flex:1,
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
        margin:'auto'
    },
    batchList:{
        flexDirection:'row',
        width:'100%',
        height:'100%',
        justifyContent:'flex-start',
        alignContent:'flex-start',
        backgroundColor:'#F00'
    },
    emptyBatchText: {
        color: COLOR_SECONDARY,
        fontSize:FONT_SIZE_HEADING,
        fontFamily:FONT_FAMILY
    }

})
