import React, {useEffect} from 'react'
import {SafeAreaView, ScrollView, StyleSheet, Text, View} from 'react-native'
import baseStyle from "../../baseStyle";
import BatchEntryStatus from "./components/BatchEntryStatus";
import {Button} from "react-native-elements";
import {useNavigation} from "@react-navigation/core";
import {batchReset} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";


export default function UploadCompleteScreen() {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const {
        isRepeat,
    } = useSelector((state) => state.batch.data);

    useEffect(()=>{dispatch(batchReset())}, []);

    return (

        <ScrollView>


            <SafeAreaView style={[baseStyle.container, {alignItems: 'center'}]}>

                <BatchEntryStatus isRepeat={isRepeat} step={4}/>

                <View style={baseStyle.raisedCard}>

                    <View>
                        <Text style={baseStyle.titleStyle}>The file upload is complete. You will be notified by email
                            when the processing is complete and the results can be downloaded. You can also check the
                            progress on the home screen.</Text>
                    </View>


                    <View style={style.buttonRow}>
                        <Button
                            buttonStyle={baseStyle.raisedButton}
                            titleStyle={baseStyle.raisedButtonText}
                            title={'START ANOTHER JOB'}
                            onPress={() => {
                                navigation.navigate('CreateBatch')
                            }}/>


                        <Button
                            buttonStyle={baseStyle.raisedButton}
                            titleStyle={baseStyle.raisedButtonText}
                            title={'RETURN TO HOME SCREEN'}
                            onPress={() => {
                                navigation.navigate('Home')
                            }}/>
                    </View>

                </View>

            </SafeAreaView>
        </ScrollView>

    )
}


const style = StyleSheet.create({
    buttonRow: {
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        margin:32
    }

});

