import React from 'react';
import {Text, View} from 'react-native'
import authScreenStyle from "../style";
import baseStyle from "../../../baseStyle";
import PhoneNumberInput from "../../../components/phoneNumberInput/PhoneNumberInput";

export default function PhoneNumberLoginInput(props) {

    return (
        <View style={baseStyle.container}>
            <Text style={[authScreenStyle.instructions,{textAlign:'center'}]}>Please enter the phone number for your account below</Text>
            <PhoneNumberInput
                countryCode={props.countryCode}
                phoneNumber={props.phoneNumber}
                onCountryCodeChange={(value)=>props.onCountryCodeChange(value)}
                onPhoneNumberChange={(value)=>props.onPhoneNumberChange(value)}
            />
        </View>

    );
}

