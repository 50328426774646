import React from 'react'
import {COLOR_PRIMARY_DARK} from "../../../baseStyle";
import {Button} from "react-native-elements";
import {useSelector} from "react-redux";

import {SERVER_URL} from "../../../store/config";
import {Linking, StyleSheet} from "react-native";

export default function BatchDownloadIcon(props) {
    const batchId = props.batchId;
    const accessToken = useSelector((state) => state.auth.accessCode);

    const downloadFile = async () => {
        try {

            const url = `${SERVER_URL}/api/batch/${batchId}/download`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                const uuid = data.value;
                const remoteUri = `${SERVER_URL}/api/batch/download/${batchId}/${uuid}`;
                await Linking.openURL(remoteUri);
            } else {
                console.error('[batchGetDownloadId] Server turned response code: ' + response.status);
            }
        } catch (e){
            console.error('[batchGetDownloadId] Exception', e);
        }

    }


    return (
        <Button
            onPress={downloadFile}
            icon={{name:'download', type:'font-awesome', size:24,  color:'white'}}
            buttonStyle={style.raisedButton}/>
    )
}



const style = StyleSheet.create({
    raisedButton:{
        backgroundColor: COLOR_PRIMARY_DARK,
        alignItems:'center',
        justifyContent:'center',
        width:36,
        height:36,
        borderRadius:18,
    }


});
