import React, {useEffect, useState} from 'react'
import DropDown from "react-native-paper-dropdown";
import baseStyle, {COLOR_SECONDARY} from "../../../baseStyle";
import {Text, View} from "react-native";
import {paperTheme} from "../../../paperTheme";


export default function NumChannelsSelection(props) {
    const [showDropDown, setShowDropDown] = useState(false);


    const value = props.value.toString();

    const onSelected=(value)=>{
        if (props.onSelected){
            const intValue = parseInt(value.replace(/[^0-9]/g, ''));
            props.onSelected(intValue.toFixed(0));
        }
    }
    const caption='Number of input channels.'
    const binList = [
        {label: "8", value: "8",},
        {label: "16", value: "16"},
    ];

    const int16List = [
        {label: "60", value: "60",},
        {label: "144", value: "144"},
        {label: "158", value: "158"},
        {label: "256", value: "256"},
        // {label: "512", value: "512"},
        // {label: "768", value: "768"},
        // {label: "1024", value: "1024"},
    ];

    const wavList = [
        {label: "2", value: "2",},
        {label: "3", value: "3"},
        {label: "4", value: "4"},
        {label: "5", value: "5"},
        {label: "6", value: "6"},
        {label: "7", value: "7"},
        {label: "8", value: "8"},
        {label: "16", value: "16"},
        {label: "60", value: "60"},
        {label: "144", value: "144"},
        {label: "158", value: "158"},
        {label: "256", value: "256"},
        // {label: "512", value: "512"},
        // {label: "768", value: "768"},
        // {label: "1024", value: "1024"}
    ];
    const otherList = [
        {label: "2", value: "2",},
        {label: "4", value: "4"},
        {label: "6", value: "6"},
        {label: "8", value: "8"},
        {label: "16", value: "16"},
    ];

    const inList=(list, value)=>{
        for (let x of list){
            if (value===x.value) return true;
        }
        return false;
    }

    useEffect(()=>{
        switch(props.fileType){
            case 'BIN':{
                if (!inList(binList, value)) {
                    if (value != binList[0].value) onSelected(binList[0].value);
                }
                break;
            }
            case 'INT16':{
                if (!inList(int16List, value)) {
                    if (value != int16List[0].value) onSelected(int16List[0].value);
                }
                break;
            }
            case 'WAV':{
                if (!inList(wavList, value)) {
                    if (value != wavList[0].value) onSelected(wavList[0].value);
                }
                break;
            }
            default:{
                if (!inList(otherList, value)) {
                    if (value != otherList[0].value) onSelected(otherList[0].value);
                }
            }
        }
    }, [props.fileType, value]);

    const getOptions = (fileType) => {
        switch (fileType){
            case 'BIN': return binList;
            case 'INT16': return int16List;
            case 'WAV': return wavList;
            default: return otherList;
        }
    }


    return (
        <View style={baseStyle.subcaptionContainerStyle}>
            <DropDown
                dropDownItemSelectedStyle={{backgroundColor:'#FFF'}}
                dropDownItemStyle={{backgroundColor:'#FFF'}}
                theme={paperTheme}
                inputProps={{style: props.style}}
                activeColor={COLOR_SECONDARY}
                label={"Number of Channels"}
                mode={"outlined"}
                visible={showDropDown}
                showDropDown={() => setShowDropDown(true)}
                onDismiss={() => setShowDropDown(false)}
                value={value}
                setValue={props.onSelected}
                list={getOptions(props.fileType)}
            />
            <Text style={baseStyle.subcaptionText}>{caption}</Text>
        </View>
    )



}

