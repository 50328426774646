export const AUTH_LOGOUT = 'AUTH_RESET_LOGIN';
export const AUTH_SET_LOGIN_TYPE = 'AUTH_SET_LOGIN_TYPE';
export const AUTH_SET_EMAIL = 'AUTH_SET_EMAIL';
export const AUTH_SET_PHONE_NUMBER = 'AUTH_SET_PHONE_NUMBER';
export const AUTH_LOGIN_REQUESTED = 'AUTH_LOGIN_REQUESTED';
export const AUTH_LOGIN_COMPLETE = 'AUTH_LOGIN_COMPLETE';
export const AUTH_REFRESH_REQUESTED = 'AUTH_REFRESH_REQUESTED';
export const AUTH_REFRESH_COMPLETE = 'AUTH_REFRESH_COMPLETE';
export const AUTH_STATE_FAILED = 'AUTH_STATE_FAILED';
export const AUTH_STATE_AUTHENTICATED = 'AUTH_STATE_AUTHENTICATED';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_REQUEST_OTP = 'AUTH_REQUEST_OTP';
export const AUTH_USER_REQUEST = 'AUTH_USER_REQUEST';
export const AUTH_USER_REQUEST_COMPLETE = 'AUTH_USER_REQUEST_COMPLETE';
