import React from 'react';
import Svg, {Path} from "react-native-svg"

export default function LogoSVG(props) {
    return (

        <Svg
            width={props.width}
            height={props.height}
            viewBox="0 0 285.76 51.26">

            <Path fill={props.color} d="M43.7,36h5.12V55.22A24.71,24.71,0,0,1,40.46,60a31.12,31.12,0,0,1-10.15,1.65A27.87,27.87,0,0,1,16.7,58.32a24.45,24.45,0,0,1-9.5-9.18A25.44,25.44,0,0,1,3.74,36,25.44,25.44,0,0,1,7.2,22.86a24.45,24.45,0,0,1,9.5-9.18,28,28,0,0,1,13.68-3.31A29.66,29.66,0,0,1,41,12.2a21.08,21.08,0,0,1,8.13,5.44L45.79,21a20.73,20.73,0,0,0-15.19-5.9,22.63,22.63,0,0,0-11,2.7,19.84,19.84,0,0,0-7.74,7.49A20.74,20.74,0,0,0,9,36a20.58,20.58,0,0,0,2.81,10.66,20.27,20.27,0,0,0,7.7,7.48,22.16,22.16,0,0,0,11,2.74A22,22,0,0,0,43.7,53Z"
                  transform="translate(-3.74 -10.37)" />
            <Path fill={props.color} d="M57.38,10.8h5.33V56.59H90.94V61.2H57.38Z" transform="translate(-3.74 -10.37)" />
            <Path fill={props.color} d="M93.31,10.8h5.33V61.2H93.31Z" transform="translate(-3.74 -10.37)" />
            <Path fill={props.color} d="M160.92,10.8V61.2h-5.11V20.88L136,54.79h-2.52L113.69,21.1V61.2h-5.12V10.8H113l21.88,37.37L156.53,10.8Z"
                  transform="translate(-3.74 -10.37)" />
            <Path fill={props.color} d="M204.84,15.41q5.46,4.61,5.47,12.67t-5.47,12.64q-5.47,4.56-15.12,4.57H176.18V61.2h-5.33V10.8h18.87Q199.37,10.8,204.84,15.41ZM201,37.33q4-3.27,4-9.25t-4-9.4q-4-3.27-11.45-3.27H176.18v25.2h13.39Q197.06,40.61,201,37.33Z"
                  transform="translate(-3.74 -10.37)" />
            <Path fill={props.color} d="M219.2,59.83a19.84,19.84,0,0,1-8-4.75l2.09-4.1a20,20,0,0,0,7.27,4.42A26.58,26.58,0,0,0,230,57.1q6.78,0,10.16-2.49a7.65,7.65,0,0,0,3.38-6.44,6.44,6.44,0,0,0-1.84-4.83,12.35,12.35,0,0,0-4.5-2.77,72.14,72.14,0,0,0-7.41-2.12,79.26,79.26,0,0,1-9.07-2.77,15,15,0,0,1-5.8-4.07,10.83,10.83,0,0,1-2.41-7.42,12.62,12.62,0,0,1,2-7,13.89,13.89,0,0,1,6.2-5A25.29,25.29,0,0,1,231,10.37a30.23,30.23,0,0,1,8.46,1.19,24.39,24.39,0,0,1,7.17,3.27l-1.8,4.25A24.63,24.63,0,0,0,238.1,16a25.12,25.12,0,0,0-7.06-1q-6.61,0-10,2.56a7.93,7.93,0,0,0-3.35,6.59,6.61,6.61,0,0,0,1.84,4.86,12.07,12.07,0,0,0,4.61,2.81q2.78,1,7.45,2.12a73.92,73.92,0,0,1,9,2.7,14.71,14.71,0,0,1,5.8,4,10.7,10.7,0,0,1,2.38,7.31,12.42,12.42,0,0,1-2.06,6.95,13.74,13.74,0,0,1-6.3,5A26.38,26.38,0,0,1,230,61.63,32.36,32.36,0,0,1,219.2,59.83Z"
                  transform="translate(-3.74 -10.37)" />
            <Path fill={props.color} d="M289.51,56.59V61.2H253.87V10.8h34.56v4.61H259.2V33.34h26.06v4.53H259.2V56.59Z"
                  transform="translate(-3.74 -10.37)" />

        </Svg>

    );
}
