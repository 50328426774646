import React from 'react'
import SimpleDropDown from "./SimpleDropDown";


export default function AdaptiveRateStepSelection(props) {

    const rateList = [];
    for (let i=1; i < 10; i++){
        rateList.push({
            value: i.toString(),
            label: i.toString()
        })
    }

    return SimpleDropDown(props, rateList);
}

