import React from 'react'
import {StyleSheet, Text, View} from "react-native";
import baseStyle, {COLOR_PRIMARY, FONT_FAMILY, FONT_SIZE_BODY} from "../../../baseStyle";
import FileEntry from "./FileEntry";

export default function FileList(props) {

    const files = props.files
    if (files !== undefined && files.length > 0) {
        return (
            <View style={style.container}>
                <View style={baseStyle.columnHeaderRow}>
                    <Text style={baseStyle.columnHeaderText}>Name</Text>
                    <Text style={baseStyle.columnHeaderText}>Size</Text>
                    <Text style={baseStyle.columnHeaderText}>Hash</Text>
                </View>
                <View style={baseStyle.batchListScrollView}>

                    {files.map((file, index) => {
                        return <FileEntry key={index} file={file}/>
                    })}
                </View>
            </View>

        )
    } else return (
        <View style={style.noFileContainer}>
            <Text style={baseStyle.noFileText}>No Files Specified</Text>
        </View>);
}



const style = StyleSheet.create({
    container:{
        flex:1,
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems:'flex-start',
        alignSelf: 'stretch',
    },
    noFileContainer:{
        flex:1,
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems: 'center',
    },
    noFileText:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_BODY,
        textAlign: 'center',
        color: COLOR_PRIMARY
    }
});
