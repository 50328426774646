import React, {useState} from 'react';
import {StyleSheet, Text} from 'react-native'
import {COLOR_GREY_LIGHTER, COLOR_WHITE, FONT_FAMILY, FONT_SIZE_BODY} from "../../baseStyle";
import {TouchableOpacity} from "react-native-web";


const cellStyle = StyleSheet.create({
    touchableContainer: {
        flex:1,
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 16,
        paddingRight:32,
        cursor:'pointer',
    },
    touchableText: {
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_BODY,
        color: '#000',
    }
})


export default function CountryCodePickerCell(props) {
    const data = props.data;
    const [hover, setHover] = useState(false);
    return (
        <TouchableOpacity
            style={[cellStyle.touchableContainer, {backgroundColor:hover?COLOR_GREY_LIGHTER:COLOR_WHITE}]}
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => {
                setHover(false);
            }}
            onPress={() => {
                props.onSelected(data.dial_code)
            }}>
            <Text style={cellStyle.touchableText}>{data.name}</Text>
            <Text style={cellStyle.touchableText}>{data.dial_code}</Text>
        </TouchableOpacity>
    )
}
