import React, {forwardRef, useImperativeHandle, useState} from 'react'
import baseStyle, {COLOR_GREY_DARK, COLOR_PRIMARY_LIGHT, FONT_FAMILY, FONT_SIZE_CAPTION} from "../../../baseStyle";
import {Dimensions, ScrollView, StyleSheet, Text, View} from "react-native";
import {Dialog, Portal} from "react-native-paper";
import {Button} from "react-native-elements";
import ReadOnlyEntry from "./ReadOnlyEntry";
import {formatDate} from "../../../store/formatUtil";
import {paperTheme} from "../../../paperTheme";
import FileList from "./FileList";


const BatchInfoDialog = (props, ref) => {
    const [dialogOptions, setDialogOptions] = useState({
        batch: {},
        visible: false,
        sourceFiles: [],
        modelFiles: []
    });


    const open = (batch) => {
        setDialogOptions({
            batch: batch,
            visible: true,
            sourceFiles: getFiles('SOURCE', batch.batchFileList),
            modelFiles: getFiles('MODEL', batch.batchFileList)
        })
    }

    const close = () => {
        setDialogOptions({
            ...dialogOptions,
            visible: false,
            sourceFiles: [],
            modelFiles: []
        })
    }

    // useMemo(() => {
    //     console.error("DIALOGOPTIONS:", dialogOptions);
    // }, [dialogOptions]);

    useImperativeHandle(ref, () => ({
        // methods connected to `ref`
        open: (batch) => {
            open(batch);
        },
        close: () => {
            close();
        }
    }))


    const maxWidth = (Dimensions.get('window').width > 1280) ? 1280 : Dimensions.get('window').width - 64;

    const getFiles = (type, batchFileList) => {
        const fArray = [];
        if (batchFileList !== undefined) {
            for (let f of batchFileList) {
                if (f.uploadType === type) fArray.push(f);
            }
        }
        return fArray;
    }


    return (
        <Portal>
            <Dialog theme={paperTheme} style={[{maxWidth: maxWidth, width: maxWidth, alignSelf: "center"}]}
                    visible={dialogOptions.visible}>
                <Dialog.Title>Job Information</Dialog.Title>
                <Dialog.Content>
                    <ScrollView>
                        <View style={style.contentRow}>
                            <ReadOnlyEntry label='Description' value={dialogOptions.batch.description}/>
                        </View>

                        <View style={style.contentRow}>
                            <ReadOnlyEntry label='Date Added' value={formatDate(dialogOptions.batch.createdEpochMs)}/>

                            {dialogOptions.batch.status === 'COMPLETE' &&
                                <ReadOnlyEntry label='Date Completed'
                                               value={formatDate(dialogOptions.batch.lastUpdatedEpochMs)}/>
                            }

                            {dialogOptions.batch.status !== 'COMPLETE' &&
                                <ReadOnlyEntry label='Date Completed' value=''/>
                            }
                            <ReadOnlyEntry label='Sample Rate' value={dialogOptions.batch.sampleRate + 'Hz'}/>
                            <ReadOnlyEntry label='Number of Channels' value={dialogOptions.batch.numChannels}/>
                            <ReadOnlyEntry label='File Type' value={dialogOptions.batch.fileType}/>
                        </View>

                        <Text style={[style.subheadStyle]}>Adaptive Filter
                            Properties</Text>
                        <View style={style.contentRow}>
                            <ReadOnlyEntry label='Frame Size Low' value={dialogOptions.batch.frameSizeLow}/>
                            <ReadOnlyEntry label='Frame Size High' value={dialogOptions.batch.frameSizeHi}/>
                            <ReadOnlyEntry label='Adaptive Rate Low' value={dialogOptions.batch.adaptiveRateLow}/>
                            <ReadOnlyEntry label='Adaptive Rate High' value={dialogOptions.batch.adaptiveRateHi}/>
                            <ReadOnlyEntry label='Adaptive Rate Step' value={dialogOptions.batch.adaptiveRateStep}/>
                        </View>


                        <View style={style.contentRow}>
                            <View style={style.contentColumn}>
                                <Text style={[style.subheadStyle]}>Model Timing
                                    Properties</Text>
                                <View style={style.contentRow}>
                                    <ReadOnlyEntry label='Model Start' value={dialogOptions.batch.modelStart + ' sec'}/>
                                    <ReadOnlyEntry label='Model End' value={dialogOptions.batch.modelEnd + ' sec'}/>
                                </View>
                            </View>

                            <View style={style.contentColumn}>
                                <Text style={[style.subheadStyle]}>Recording
                                    Timing Properties</Text>
                                <View style={style.contentRow}>
                                    <ReadOnlyEntry label='Process Start'
                                                   value={dialogOptions.batch.processStart + ' sec'}/>
                                    <ReadOnlyEntry label='Process End' value={dialogOptions.batch.processEnd + ' sec'}/>
                                </View>
                            </View>

                            <View style={style.contentColumn}>
                                <Text style={[style.subheadStyle]}>Source Location Spatial Model Estimator
                                    Parameters</Text>
                                <View style={style.contentRow}>
                                    <ReadOnlyEntry label='Gate Level GF' value={dialogOptions.batch.gateLevelGF + '%'}/>
                                    <ReadOnlyEntry label='Min Model Length GF'
                                                   value={dialogOptions.batch.midModelLengthGF + ' sec'}/>
                                </View>
                            </View>
                        </View>


                        <View style={[style.contentRow, {marginTop: 16}]}>
                            <View style={style.contentColumn}>
                                <Text style={[style.subheadStyle]}>&nbsp;</Text>
                                <View style={style.contentRow}>
                                    <ReadOnlyEntry label='Utterance Length'
                                                   value={dialogOptions.batch.utteranceLength + ' ms'}/>
                                    <ReadOnlyEntry label='Gate Level D' value={dialogOptions.batch.gateLevelD + '%'}/>
                                </View>
                            </View>

                            <View style={style.contentColumn}>
                                <Text style={[style.subheadStyle]}>Delay Estimator Parameters</Text>
                                <View style={style.contentRow}>
                                    <ReadOnlyEntry label='Min Model Length D'
                                                   value={dialogOptions.batch.midModelLengthD + ' sec'}/>
                                    <ReadOnlyEntry label='Low Pass' value={dialogOptions.batch.lowPass + ' Hz'}/>
                                </View>
                            </View>

                            <View style={style.contentColumn}>
                                <Text style={[style.subheadStyle]}>&nbsp;</Text>
                                <View style={style.contentRow}>
                                    <ReadOnlyEntry label='High Pass' value={dialogOptions.batch.highPass + ' Hz'}/>
                                    <ReadOnlyEntry label='Array Width' value={dialogOptions.batch.arrayWidth + ' cm'}/>
                                </View>
                            </View>


                        </View>

                        <View style={[style.contentRow, {marginTop: 24}]}>
                            <View style={style.contentColumn}>
                                <Text style={style.subheadStyle}>Source Files</Text>
                                <FileList files={dialogOptions.sourceFiles}/>
                            </View>


                            <View style={style.contentColumn}>
                                <Text style={style.subheadStyle}>Model Files</Text>
                                <FileList files={dialogOptions.modelFiles}/>
                            </View>


                        </View>


                    </ScrollView>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button
                        type={'clear'}
                        titleStyle={baseStyle.clearButtonText}
                        onPress={() => {
                            close();
                        }}
                        title='CLOSE'/>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}


const style = StyleSheet.create({
    dialogText: {
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_CAPTION,
        color: COLOR_GREY_DARK,
    },
    contentRow: {
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
    },
    contentColumn: {
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        marginRight: 4,
        marginLeft: 4
    },
    subheadStyle: {
        fontSize: FONT_SIZE_CAPTION,
        fontFamily: FONT_FAMILY,
        color: COLOR_PRIMARY_LIGHT,
        textTransform: "uppercase",
        fontWeight: "bolder",
        padding: 8,
        textAlign: 'center'
    },
});


export default forwardRef(BatchInfoDialog);
