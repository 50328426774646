import React, {useState} from 'react';
import {View} from 'react-native'
import CountryCodePicker from "./CountryCodePicker";
import {Button} from "react-native-elements";

export default function CountryCodeDropdown(props) {

    const [showPicker, setShowPicker] = useState(false);

    return (
        <View>
            <Button title={props.value}
                    buttonStyle={props.buttonStyle}
                    titleStyle={props.titleStyle}
                    icon={{
                        name: 'caret-down',
                        type: 'font-awesome',
                        size: 15,
                        color: 'black',
                    }}
                    iconRight

                    onPress={() => {
                        setShowPicker(true);
                    }}/>

            <CountryCodePicker value={props.value}
                               onSelected={(value) => {
                                   setShowPicker(false);
                                   props.onChange(value);
                               }}
                               visible={showPicker}/>
        </View>
    );
}


