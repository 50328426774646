import React, {forwardRef, useImperativeHandle, useState} from 'react'
import baseStyle, {
    COLOR_GREY_DARK,
    COLOR_RED,
    FONT_FAMILY,
    FONT_SIZE_BODY,
    FONT_SIZE_CAPTION
} from "../../../baseStyle";
import {StyleSheet, Text} from "react-native";
import {Dialog, Portal} from "react-native-paper";
import {Button} from "react-native-elements";
import {paperTheme} from "../../../paperTheme";


const BatchValidationErrorDialog = (props, ref) =>{
    const [dialogOptions, setDialogOptions] = useState({
        errors:[],
        visible:false
    });

    const open = (errors) => {
        setDialogOptions({
            errors: errors,
            visible:true
        })
    }

    const close = () => {
        setDialogOptions({
            ...dialogOptions,
            errors:[],
            visible:false
        })
    }

    useImperativeHandle(ref, () => ({
        // methods connected to `ref`
        open: (errors) => { open(errors);},
        close: () => { close(); }
    }))


    return (
        <Portal >
            <Dialog theme={paperTheme} style={[{ maxWidth: 800, alignSelf: "center" }]} visible={dialogOptions.visible}>
                <Dialog.Title>Job Parameter Errors</Dialog.Title>
                <Dialog.Content >
                    <Text style={baseStyle.dialogText}>The following errors need to be corrected before you can submit the job:</Text>

                    {dialogOptions.errors.map((error, index) => {
                        return <Text style={style.errorStyle} key={index}>{error}</Text>
                    })}

                </Dialog.Content>
                <Dialog.Actions>
                    <Button
                        type={'clear'}
                        titleStyle={baseStyle.clearButtonText}
                        onPress={()=>{
                            close();
                        }}
                        title='CLOSE'/>

                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}



const style = StyleSheet.create({
    dialogText:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_CAPTION,
        color: COLOR_GREY_DARK,
    },
    errorStyle:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_BODY,
        color: COLOR_RED,
        margin:4,
        marginLeft:8,

    }

});


export default forwardRef(BatchValidationErrorDialog);
