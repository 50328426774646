import {createStackNavigator} from '@react-navigation/stack'
import React from 'react';
//Sub Navigation
import Login from "../screens/Auth/Login";
import OneTimePassword from "../screens/Auth/OneTimePassword";

const Stack = createStackNavigator();

/**
 * Top level navigator for the Auth workflow.
 * @return {JSX.Element}
 * @constructor
 */
export default function AuthNavigator() {
    return (
        <Stack.Navigator screenOptions={{
            headerShown: false,
            headerMode: 'screen',
            gestureEnabled: false,
        }}>
            <Stack.Screen
                name="LoginScreen"
                component={Login}
                options={{title: 'GLIMPSE'}}
            />
            <Stack.Screen
                name="OneTimePasswordScreen"
                component={OneTimePassword}
                options={{title: 'GLIMPSE'}}
            />
        </Stack.Navigator>
    );
}

