import React from 'react'
import {Image, ImageBackground, ScrollView, Text, View} from 'react-native'
import style from './style'
import EmailAddressInput from "./components/EmailAddressInput";
import {useDispatch, useSelector} from "react-redux";
import {
    authRequestOneTimePassword,
    authSetEmailAddress,
    authSetPhoneNumber,
    authUseEmailForLogin
} from "../../store/actions";
import baseStyle, {COLOR_PRIMARY} from "../../baseStyle";
import {SafeAreaView} from "react-native-web";
import PhoneNumberLoginInput from "./components/PhoneNumberLoginInput";
import {useNavigation} from "@react-navigation/core";
import LogoSVG from "../../svg/logo";
import {Button} from "react-native-elements";


export default function Login() {
    const dispatch = useDispatch();
    const email = useSelector((state) => state.auth.email);
    const countryCode = useSelector((state) => state.auth.countryCode);
    const phoneNumber = useSelector((state) => state.auth.phoneNumber);
    const useEmail = useSelector((state) => state.auth.useEmail);
    const navigation = useNavigation();

    const requestOTP = async(username)=>{
        dispatch(authRequestOneTimePassword(username));
        navigation.navigate('OneTimePasswordScreen');
    }

    const isValid = () => {
        return true;
    }

    return (
        <ImageBackground resizeMode="cover"
                         style={{height: '100%', width: '100%'}}
                         source={require('../../../assets/teaser-bg-restoringHearing.png')}>

        <SafeAreaView style={baseStyle.container}>
            <ScrollView style={baseStyle.containerScrollView}>
                <View style={{flexDirection: 'column', margin: 'auto'}}>
                    <Image
                        style={style.logoImage}
                        source={require('../../../assets/Wave_Sciences_Logo.png')}
                    />
                    <View style={style.centeredBox}>
                        <LogoSVG height={70} color={COLOR_PRIMARY} style={style.logoImage}/>

                        {/*Show component based on selected type.*/}
                        {useEmail?
                            <EmailAddressInput
                                value={email}
                                onChanged={(email) => {dispatch(authSetEmailAddress(email))}}
                            />:
                            <PhoneNumberLoginInput
                                countryCode={countryCode}
                                phoneNumber={phoneNumber}
                                onCountryCodeChange={(value)=>{dispatch(authSetPhoneNumber(value, phoneNumber))}}
                                onPhoneNumberChange={(value)=>{dispatch(authSetPhoneNumber(countryCode, value))}}
                            />
                        }



                        <Button
                            title='NEXT'
                            buttonStyle={baseStyle.raisedButton}
                            titleStyle={baseStyle.raisedButtonText}
                            onPress={() => {
                                if (isValid()) {
                                    const username = useEmail ? email : countryCode + phoneNumber;
                                    requestOTP(username);
                                }
                            }}
                        />



                        <View style={[style.orBox, {marginTop:32}]}>
                            <View style={style.orDash}></View>
                            <Text style={style.orText}>or</Text>
                            <View style={style.orDash}></View>
                        </View>


                        <Button
                            type='clear'
                            title={useEmail ? 'Use Phone Number Instead' : 'Use Email Instead'}
                            titleStyle={[baseStyle.clearButtonText, style.orButtonText]}
                            onPress={() => {
                                dispatch(authUseEmailForLogin(!useEmail))
                            }}
                        />


                    </View>



                </View>


            </ScrollView>
        </SafeAreaView>
        </ImageBackground>
    )
}

