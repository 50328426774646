import {existsString} from "./validation";

export const getExtension = (fileType) => {
  if (existsString(fileType)) {
    return `.${fileType.toLowerCase()}`;
  }
  return '';
}

export const getSequence = (fileType, extension, fileName) => {
  if ((fileType === 'BIN') || (fileType === 'INT16')) {
    return 0;
  }

  if (existsString(fileName) && existsString(extension)) {
    const startIndex = fileName.length - (3 + extension.length);
    const seqString = fileName.substring(startIndex, startIndex+3);
    return parseInt(seqString);
  }
  return 0;
}
