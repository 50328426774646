import React, {useRef} from 'react'
import {StyleSheet, Text, View} from "react-native";
import {unstable_createElement} from "react-native-web";
import {COLOR_GREY_LIGHTER, COLOR_PRIMARY, FONT_FAMILY, FONT_SIZE_HEADING} from "../../../baseStyle";


export default function FileDragAndDrop(props) {
    const disabled = props.disabled;

    const DragDiv = (props) => unstable_createElement('div', props);
    const fileRef = useRef(null);

    const FileDiv = (props) => unstable_createElement('input', {
        ...props,
        ref: fileRef
    });


    const onDragOver = (evt) => {
        evt.preventDefault();
        return false;
    }

    const onSelected = (evt) => {
        for (let i = 0; i < evt.target.files.length; i++) {
            onFile(evt.target.files[i]);
        }
    }
    const onDrop = (evt) => {
        evt.preventDefault();
        if (evt.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < evt.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (evt.dataTransfer.items[i].kind === 'file') {
                    const file = evt.dataTransfer.items[i].webkitGetAsEntry();
                    onFile(file);
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (let i = 0; i < evt.dataTransfer.files.length; i++) {
                onFile(evt.dataTransfer.files[i]);
            }
        }
    }

    const onSelectFileClick = async (evt) => {
        fileRef.current.click();
    };

    const fileEntryToFile = async (fileEntry) => {
        return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    }

    const onFile = async (file) => {
        if (disabled) return;

        if (file instanceof File) {
            console.log('[onFile] File detected', file);
            props.onFileFound(file);
        } else if (file.isDirectory) {
            console.log('[onFile] Directory detected', file);
            const dirReader = file.createReader();
            dirReader.readEntries(async (entries) => {
                for (let i = 0; i < entries.length; i++) {
                    await onFile(entries[i]);
                }
            });
        } else if (file.isFile && props.onFileFound) {
            console.log('[onFile] FileEntry File detected', file);
            const f = await fileEntryToFile(file);
            props.onFileFound(f);
        }
    }

    if (disabled) {
        return (
            <View style={[style.outerContainer, style.disabled]}>
                <View style={style.innerContainer}>
                    <Text style={style.innerText}>&nbsp;</Text>
                    <Text style={style.innerText}>ALL FILES SELECTED</Text>
                    <Text style={style.innerText}>&nbsp;</Text>
                </View>
            </View>
        )

    } else return (
        <DragDiv style={style.outerContainer}
                 onDragOver={onDragOver}
                 onDrop={onDrop}
        >

            <View style={style.innerContainer} onClick={onSelectFileClick}>
                <Text style={style.innerText}>DROP FILES HERE</Text>
                <Text style={style.innerText}>OR</Text>
                <Text style={style.innerText}>CLICK TO SELECT</Text>
                <FileDiv type='file' style={{display: 'none'}} onChange={onSelected}/>
            </View>


        </DragDiv>
    )
}


const style = StyleSheet.create({
    outerContainer: {
        margin: 32
    },
    innerContainer: {
        backgroundColor: COLOR_GREY_LIGHTER,
        flex: 1,
        padding: 32,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: COLOR_PRIMARY,
        borderRadius: 8
    },
    innerText: {
        color: COLOR_PRIMARY,
        fontSize: FONT_SIZE_HEADING,
        fontFamily: FONT_FAMILY,
        textAlign: 'center',
        padding: 8,
    },
    disabled:{
        opacity:0.6
    }
});
