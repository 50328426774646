import {PERMISSIONS_ALLOWED, PERMISSIONS_DENIED} from "../actionTypes/permissions";

const initialHistory = {
  allowPermission:undefined,
}

export const permissions = (state = JSON.parse(JSON.stringify(initialHistory)), action = {}) => {
  switch (action.type) {


    case PERMISSIONS_DENIED:{
      return {
        ...state,
        allowPermission:false,
      }
    }

    case PERMISSIONS_ALLOWED:{
      return {
        ...state,
        allowPermission:true,
      }
    }

    default:
      return state
  }
}

