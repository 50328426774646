import React from 'react'
import {ScrollView, StyleSheet, Text, View} from "react-native";
import baseStyle from "../../../baseStyle";
import SelectedFileListEntry from "./SelectedFileListEntry";


export default function SelectedFileList(props) {
    const {files, numChannels,fileType} = props;
    const expectedFiles = (fileType==='INT16' || fileType==='BIN')?1:numChannels;
    const selectedFilesString = `SELECTED FILES (${files.length} of ${expectedFiles})`;

    return (
        <View style={style.container}>
            <Text style={[baseStyle.subheadStyle, {marginTop:0, marginBottom:0}]}>{selectedFilesString}</Text>
            <ScrollView>
                {files.map((file, index)=>{
                    return <SelectedFileListEntry isRepeat={props.isRepeat} key={index} file={file} onDelete={props.onDelete}/>
                })}
            </ScrollView>
        </View>

    )
}



const style = StyleSheet.create({
    container:{
        maxHeight:300,
        marginBottom:32
    }
});
