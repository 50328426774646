import React from 'react'
import {StyleSheet, Text, View} from "react-native";

import {COLOR_BLACK, COLOR_GREY_LIGHT, COLOR_WHITE, FONT_FAMILY, FONT_SIZE_BODY} from "../../../baseStyle";
import {humanFileSize} from "../../../store/formatUtil";

const parseFilename = (fileName) => {
    if (fileName.indexOf("/") >= 0){
        return filename.substring(fileName.lastIndexOf("/") + 1);
    } else {
        return fileName;
    }
}

export default function FileEntry(props) {
    const file = props.file;
    const fileName = parseFilename(file.fileName);

    return (

        <View style={style.container}>
            <Text style={style.entryText}>{fileName}</Text>
            <Text style={style.entryText}>{humanFileSize(file.fileSize)}</Text>
            <Text style={style.entryText}>{file.fileHash}</Text>
        </View>
    )
}


const style = StyleSheet.create({
    container:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        padding:8,
        paddingLeft:16,
        backgroundColor:COLOR_WHITE,
        borderRadius:8,
        borderStyle:'solid',
        borderColor: COLOR_GREY_LIGHT,
        borderWidth:1,
        margin:4
    },
    entryText:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_BODY,
        color: COLOR_BLACK,
        flex:1,
        textAlignVertical:'top',
        width: 200,
        padding:16,
        paddingRight:0,
        paddingBottom:0,
        paddingTop:0,
        height:'100%'
    },


});
