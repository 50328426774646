import React, {useState} from 'react'
import {SafeAreaView, ScrollView, Text, View} from 'react-native'
import {useDispatch, useSelector} from "react-redux";
import {useNavigation} from "@react-navigation/core";
import baseStyle from "../../baseStyle";
import BatchEntryStatus from "./components/BatchEntryStatus";
import {batchAddFile, batchRemoveFile} from "../../store/actions";
import {Button} from "react-native-elements";
import FileDragAndDrop from "./components/FileDragAndDrop";
import SelectedFileList from "./components/SelectedFileList";
import {Dialog, Portal} from "react-native-paper";
import {endsWithChannel, hasFileExtension} from "../../store/validation";
import {getExtension, getSequence} from "../../store/parseUtil";
import {paperTheme} from "../../paperTheme";

export default function SelectFilesScreen() {
    const dispatch = useDispatch();
    const navigation = useNavigation();

    const [error, setError] = useState({
        visible:false,
        title:'',
        message:''
    });

    const {
        files,
    } = useSelector((state) => state.batch);

    const {
        isRepeat,
        fileType,
        numChannels,
    } = useSelector((state) => state.batch.data);


    const expectedFiles = (fileType==='INT16' || fileType==='BIN')?1:numChannels;
    const extension = getExtension(fileType);
    const disableNext = files.length != expectedFiles;

    const verifyFileAdd = (file) => {

        if (files && (files.length >= expectedFiles)){
            setError({visible: true, title:'Too many files', message:'There should only be one file per channel.'})
            return false;
        }

        if (!hasFileExtension(file.name, extension)){
            setError({visible: true, title:'Incorrect File Type', message:`The file should have the extension ${extension}`});
            return false;
        }

        if (fileType !== 'BIN' && fileType !== 'INT16'){


            if (!endsWithChannel(file.name)){
                setError({visible: true, title:'Invalid File Name', message:`The file name should end with _CHxxx${extension} where xxx is the channel number. For example, AudioSample_CH001${extension}`})
                return false;
            }

            const fileSequence = getSequence(fileType, extension, file.name);
            if (fileSequence < 1 || fileSequence > numChannels){
                setError({visible: true, title:'Sequence Out of Range', message:`The file ${file.name} is using a sequence that is out of range (1-${numChannels})`});
                return false;
            }

            //Does another file have the same channel assignment
            for (let existingFile of files){
                if (getSequence(fileType, extension, existingFile.name) === fileSequence){
                    const eName = existingFile.name;
                    const eSeq = eName.substring(eName.length - (3 + extension.length), eName.length - extension.length);
                    setError({visible: true, title:'Duplicate Sequence', message:`The file ${eName} already is using sequence ${eSeq}`});
                    return false;
                }
            }



        }


        return true;
    }

    return (

        <ScrollView>
            <Portal >
                <Dialog theme={paperTheme}  style={{ maxWidth: 800, alignSelf: "center" }} visible={error.visible}>
                    <Dialog.Title>{error.title}</Dialog.Title>
                    <Dialog.Content >
                        <Text style={baseStyle.dialogText}>{error.message}</Text>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button
                            type={'clear'}
                            titleStyle={baseStyle.clearButtonText}
                            onPress={()=>setError({visible: false, title:'', message:''})}
                            title='Close'/>
                    </Dialog.Actions>
                </Dialog>
            </Portal>

            <SafeAreaView style={[baseStyle.container, {alignItems: 'center'}]}>

                <BatchEntryStatus isRepeat={isRepeat} step={1}/>


                <View style={baseStyle.raisedCard}>

                    {(!isRepeat && (fileType === 'BIN')) &&
                        <View>
                            <Text style={baseStyle.titleStyle}>Drag and Drop the source .bin file to be processed</Text>
                            <Text style={baseStyle.instructions}>Only one file needs to be uploaded. The server will split the file into individual channels.</Text>
                        </View>
                    }

                    {(!isRepeat && (fileType === 'INT16')) &&
                        <View>
                            <Text style={baseStyle.titleStyle}>Drag and Drop the source .int16 file to be processed</Text>
                            <Text style={baseStyle.instructions}>Only one file needs to be uploaded. The server will split the file into individual channels.</Text>
                        </View>
                    }

                    {(!isRepeat && (fileType !== 'BIN' && fileType !=='INT16' )) &&
                        <View>
                            <Text style={baseStyle.titleStyle}>Drag and Drop the source .{extension} files to be processed</Text>
                            <Text style={baseStyle.instructions}>Each file must end in _CHxxx{extension} where xxx is the number of the channel. {numChannels} files (one per channel) must be uploaded.</Text>
                        </View>
                    }


                    {!isRepeat &&
                        <FileDragAndDrop
                            disabled={!disableNext}
                            onFileFound={(file) => {

                                if (verifyFileAdd(file)) {
                                    dispatch(batchAddFile(file));
                                }
                            }}
                        />
                    }

                    {(isRepeat) &&
                        <View>
                            <Text style={baseStyle.titleStyle}>Review Source Files</Text>
                            <Text style={baseStyle.instructions}>The files listed below will be processed as the GLIMPSE source files.</Text>
                        </View>
                    }

                    {(files && files.length) > 0 &&
                        <SelectedFileList
                            isRepeat={isRepeat}
                            files={files}
                            numChannels={numChannels}
                            fileType={fileType}
                            onDelete={(file)=>{
                                dispatch(batchRemoveFile(file));
                            }}
                        />
                    }



                    <View style={baseStyle.buttonContainer}>

                        <Button
                            buttonStyle={baseStyle.raisedButton}
                            titleStyle={baseStyle.raisedButtonText}
                            title={'CANCEL'}
                            onPress={async ()=>{
                                navigation.navigate('Home')
                            }}/>


                        <Button
                            buttonStyle={baseStyle.raisedButton}
                            titleStyle={baseStyle.raisedButtonText}
                            title={'BACK'}
                            onPress={()=>{navigation.navigate('CreateBatch')}}
                        />

                        <Button
                            buttonStyle={baseStyle.raisedButton}
                            titleStyle={baseStyle.raisedButtonText}
                            disabled={disableNext}
                            title={'NEXT'}
                            onPress={async ()=>{

                                // await dispatch(batchStartUpload());
                                // navigation.navigate('UploadFiles');
                                navigation.navigate('SelectModelFiles');
                            }}/>
                    </View>

                </View>

            </SafeAreaView>
        </ScrollView>

    )
}

