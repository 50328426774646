import React, {useEffect} from 'react';
import {Dimensions, InteractionManager, StyleSheet, Text} from 'react-native'
import {CodeField, useBlurOnFulfill, useClearByFocusCell} from "react-native-confirmation-code-field";

//Calculate the multiplier based on how large the window is if it's > than a specific size
const windowWidth = Dimensions.get("window").width;

const multiplier = ( windowWidth > 480)?2.0:1.0;

const style = StyleSheet.create({
    root: {flex: 1, padding: 20},
    title: {textAlign: 'center', fontSize: 30},
    codeFieldRoot: {marginTop: 20},
    cell: {
        width: 40 * multiplier,
        height: 40 * multiplier,
        lineHeight: 38 * multiplier,
        fontSize: 24 * multiplier,
        borderWidth: 2,
        borderColor: '#00000030',
        borderRadius:4,
        textAlign: 'center',
    },
    focusCell: {
        borderColor: '#000',
    },
});

function Cursor() {
    return null;
}

export default function OtpCodeInput(props) {
    const CELL_COUNT = 6;
    const ref = useBlurOnFulfill({value, cellCount: CELL_COUNT});
    const value = value;
    const setValue = props.onChange;

    const [xProps, getCellOnLayoutHandler] = useClearByFocusCell({
        value,
        setValue
    });
    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            ref.current?.focus();
        });
    }, []);
    return (

            <CodeField
                ref={ref}
                {...props}
                // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
                value={props.value}
                onChangeText={props.onChange}
                cellCount={CELL_COUNT}
                keyboardType="number-pad"
                textContentType="oneTimeCode"
                renderCell={({index, symbol, isFocused}) => (
                    <Text
                        key={index}
                        style={[style.cell, isFocused && style.focusCell]}
                        onLayout={getCellOnLayoutHandler(index)}>
                        {symbol || (isFocused ? <Cursor/> : null)}
                    </Text>
                )}
            />

    );
}

