import {StyleSheet} from 'react-native'
import {COLOR_GREY_DARKER, COLOR_GREY_LIGHTER, COLOR_WHITE, FONT_FAMILY, FONT_SIZE_CAPTION} from "../baseStyle";

export const MARGIN = 16
export const MARGIN_MULTIPLIER = 1.5
const topBorder = {
  borderTopColor: COLOR_GREY_LIGHTER,
  borderTopWidth: 1,
  paddingTop: MARGIN,
}


export default StyleSheet.create({
  scrollWrapper: {
    flexDirection: 'column',
    backgroundColor: COLOR_WHITE,
    alignItems: 'stretch',
    paddingBottom: MARGIN * MARGIN_MULTIPLIER,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: COLOR_WHITE
  },
  item: {
    margin: MARGIN, // to match reactnavigation
    fontFamily: FONT_FAMILY,
    fontWeight: "bold",
    color: COLOR_GREY_DARKER,
  },
  itemTitle: {
    fontSize: FONT_SIZE_CAPTION,
    color: COLOR_GREY_LIGHTER,
  },
  userContainer: {
    marginHorizontal: MARGIN,
    marginBottom: MARGIN,
  },
  accountTitleAndManageButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  manageAccountIcon: {
    color: COLOR_GREY_DARKER,
  },
  menuItems: {
    ...topBorder,
  },
})
