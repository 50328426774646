import {createStackNavigator} from '@react-navigation/stack'
import React from 'react';
import {createDrawerNavigator} from "@react-navigation/drawer";

import {COLOR_BLUE, COLOR_GREY_DARKER, COLOR_PRIMARY, COLOR_WHITE} from "../baseStyle";
import HomeScreen from "../screens/App/HomeScreen";
import CreateBatchScreen from "../screens/App/CreateBatchScreen";
import SelectFilesScreen from "../screens/App/SelectFilesScreen";
import FilesUploadingScreen from "../screens/App/FilesUploadingScreen";
import UploadCompleteScreen from "../screens/App/UploadCompleteScreen";
import SelectModelScreen from "../screens/App/SelectModelScreen";
import DrawerContent from "./DrawerContent";
import UsersScreen from "../screens/App/UsersScreen";

const Stack = createStackNavigator();

/**
 * Top level navigator for the App workflow after the user has been authentiated.
 * @return {JSX.Element}
 * @constructor
 */
function HomeStackNavigator() {
    return (
        <Stack.Navigator screenOptions={{
            title:'GLIMPSE',
            headerShown: false,
            headerMode: 'screen',
            gestureEnabled: false,
        }}>

            <Stack.Screen
                name="Home"
                component={HomeScreen}
            />

            <Stack.Screen
                options={{title:'GLIMPSE'}}
                name="CreateBatch"
                component={CreateBatchScreen}
            />



            <Stack.Screen
                name="SelectFiles"
                component={SelectFilesScreen}
            />
            <Stack.Screen
                name="SelectModelFiles"
                component={SelectModelScreen}
            />
            <Stack.Screen
                name="UploadFiles"
                component={FilesUploadingScreen}
            />
            <Stack.Screen
                name="UploadComplete"
                component={UploadCompleteScreen}
            />

        </Stack.Navigator>
    );
}


function UserStackNavigator() {
    return (
        <Stack.Navigator screenOptions={{
            title:'USERS',
            headerShown: false,
            headerMode: 'screen',
            gestureEnabled: false,
        }}>

            <Stack.Screen
                name="Users"
                component={UsersScreen}
            />
        </Stack.Navigator>
    );
}


const Drawer = createDrawerNavigator();

export default function AppNavigator() {
    let homeTitle = 'GLIMPSE';

    return (
        <Drawer.Navigator
            drawerContent={(props) => <DrawerContent {...props} />}
            screenOptions={{
                drawerInactiveTintColor: COLOR_GREY_DARKER,
                drawerActiveTintColor: COLOR_BLUE,
                drawerActiveBackgroundColor: 'transparent',
                drawerStyle: {
                    backgroundColor: '#c6cbef',
                    width: 240,
                },
            }}>
            <Drawer.Screen name="Home"

                           options={({route, navigation}) => {
                               return ({
                                   title: 'Home',
                                   headerTitleAlign: 'center',
                                   headerTintColor: COLOR_WHITE,
                                   headerTitle: homeTitle,
                                   drawerLabelStyle: {
                                       fontWeight: 'bold'
                                   },
                                   headerStyle: {
                                       backgroundColor: COLOR_PRIMARY,
                                   },
                                   //                               headerLeft: props => <MenuHeaderLeft options={{}} navigation={navigation} {...props}/>,
                               });
                           }}
                           component={HomeStackNavigator}/>

                    <Drawer.Screen name="Users"

                           options={({route, navigation}) => {
                               return ({
                                   title: 'Users',
                                   headerTitleAlign: 'center',
                                   headerTintColor: COLOR_WHITE,
                                   headerTitle: 'Users',
                                   drawerLabelStyle: {
                                       fontWeight: 'bold'
                                   },
                                   headerStyle: {
                                       backgroundColor: COLOR_PRIMARY,
                                   },
                               });
                           }}
                           component={UserStackNavigator}/>
        </Drawer.Navigator>
    );
}


