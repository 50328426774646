import React, {forwardRef, useImperativeHandle, useState} from 'react'
import baseStyle, {
    COLOR_GREY_DARK,
    COLOR_RED,
    COLOR_SECONDARY,
    FONT_FAMILY,
    FONT_SIZE_CAPTION
} from "../../../baseStyle";
import {StyleSheet, Text} from "react-native";
import {Dialog, Portal, TextInput} from "react-native-paper";
import {Button} from "react-native-elements";
import {paperTheme} from "../../../paperTheme";
import {batchUpdate} from "../../../store/actions";


const UserAddDialog = (props, ref) =>{
    const [dialogOptions, setDialogOptions] = useState({
        email:'',
        phoneNumber:'',
        visible:false,
    });

    const open = () => {
        setDialogOptions({
            email:'',
            phoneNumber:'',
            visible:true
        })
    }

    const close = () => {
        setDialogOptions({
            ...dialogOptions,
            visible:false
        })
    }

    useImperativeHandle(ref, () => ({
        // methods connected to `ref`
        open: () => { open();},
        close: () => { close(); }
    }))


    return (
        <Portal >
            <Dialog theme={paperTheme} style={[{ maxWidth: 800, alignSelf: "center" }]} visible={dialogOptions.visible}>
                <Dialog.Title>Add User</Dialog.Title>
                <Dialog.Content >
                    <Text style={baseStyle.dialogText}>Please enter the fields below for the new user:</Text>

                    <TextInput
                        theme={paperTheme}
                        activeOutlineColor={COLOR_SECONDARY}
                        style={baseStyle.textFieldStyle}
                        label='Email'
                        mode='outlined'
                        value={dialogOptions.email}
                        onChangeText={(value) => setDialogOptions({...dialogOptions, email:value})}
                    />

                    <TextInput
                        theme={paperTheme}
                        activeOutlineColor={COLOR_SECONDARY}
                        style={baseStyle.textFieldStyle}
                        label='Phone Number (+18885551212)'
                        mode='outlined'
                        value={dialogOptions.phoneNumber}
                        onChangeText={(value) => setDialogOptions({...dialogOptions, phoneNumber:value})}
                    />

                </Dialog.Content>
                <Dialog.Actions>
                    <Button
                        type={'clear'}
                        titleStyle={baseStyle.clearButtonText}
                        onPress={()=>{
                            close();
                        }}
                        title='CANCEL'/>

                    <Button
                        type={'clear'}
                        titleStyle={[baseStyle.clearButtonText,{color:COLOR_RED}]}
                        onPress={()=>{
                            close();
                            props.onAdd(dialogOptions.email, dialogOptions.phoneNumber);
                        }}
                        title='CREATE'/>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}



const style = StyleSheet.create({
    dialogText:{
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE_CAPTION,
        color: COLOR_GREY_DARK,
    }
});


export default forwardRef(UserAddDialog);
