import React, {useState} from 'react'
import DropDown from "react-native-paper-dropdown";
import baseStyle, {COLOR_SECONDARY} from "../../../baseStyle";
import {Text, View} from "react-native";
import {paperTheme} from "../../../paperTheme";


export default function FileTypeSelection(props) {
    const [showDropDown, setShowDropDown] = useState(false);


    const valueList = [
        {label: "BIN", value: "BIN",},
        {label: "INT16", value: "INT16"},
        {label: "AIFF", value: "AIFF"},
        {label: "AU", value: "AU"},
        {label: "FLAC", value: "FLAC"},
        {label: "WAV", value: "WAV"},
    ];

    return (
        <View style={baseStyle.subcaptionContainerStyle}>
        <DropDown
            dropDownItemSelectedStyle={{backgroundColor:'#FFF'}}
            dropDownItemStyle={{backgroundColor:'#FFF'}}

            theme={paperTheme}
            inputProps={{style: props.style}}
            activeColor={COLOR_SECONDARY}
            label={"File Type"}
            mode={"outlined"}
            visible={showDropDown}
            showDropDown={() => setShowDropDown(true)}
            onDismiss={() => setShowDropDown(false)}
            value={props.value}
            setValue={(value)=>{
                if (props.onSelected) props.onSelected(value)
            }}
            list={valueList}
        />
            <Text style={[baseStyle.subcaptionText, {maxWidth:300}]}>
                The source and model file format. Only uncompressed or lossless compressed files should be submitted.
            </Text>
        </View>
    )
}

