import {APP_SET_TIMER_PUMP_HANDLE, APP_START_TIMER, APP_STOP_TIMER} from "../actionTypes/app";

const initialState = {
  timerPumpIntervalHandle:undefined,
  isRunning: false
}

// Top-level app reducer for global user state.
export const app = (state = {...initialState}, action = {}) => {
  switch (action.type) {

    case APP_START_TIMER:{
      return {
        ...state,
        isRunning: true
      }
    }

    case APP_STOP_TIMER:{
      return {
        ...state,
        isRunning: false
      }
    }
    case APP_SET_TIMER_PUMP_HANDLE:{
      return {
        ...state,
        timerPumpIntervalHandle: action.value
      }
    }
    default:
      return state
  }
}

