import React from 'react'
import {StyleSheet, Text, View} from "react-native";
import {COLOR_BLACK, COLOR_GREY_DARK, FONT_FAMILY, FONT_SIZE_BODY, FONT_SIZE_CAPTION} from "../../../baseStyle";


export default function ReadOnlyEntry(props) {
    const label = props.label;
    const value = props.value;

        return (
            <View style={style.container}>
                <Text style={style.label}>{label}</Text>
                <Text style={style.value}>{value}</Text>

            </View>
        )
}


const style = StyleSheet.create({
    container:{
      flex:1,
      flexDirection:'column',
      justifyContent:'flex-start',
      alignItems:'flex-start'
    },

    label: {
        color: COLOR_GREY_DARK,
        fontSize: FONT_SIZE_CAPTION,
        fontFamily: FONT_FAMILY,
        padding:4
    },
    value: {
        padding:4,
        color: COLOR_BLACK,
        fontSize: FONT_SIZE_BODY,
        fontFamily: FONT_FAMILY,
    },

});
