export const existsString = (s) => {
  return (s!==undefined) && (s!==null) && (s.trim().length > 0);
}

export const isInteger =(str)=> {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseInt(str)) // ...and ensure strings of whitespace fail
}

export const endsWithChannel = (fileName) =>{
  if (!existsString(fileName)) return false;
  fileName = fileName.toUpperCase();
  //Strip out file extension
  fileName = fileName.substring(0, fileName.lastIndexOf('.'));

  if (fileName.lastIndexOf('_CH') !== (fileName.length - 6)) return false;

  //Make sure the sequence is a 3 digit number
  const seq = fileName.substring(fileName.length - 3);
  const regex = /[\+]?\d{3}/
  const seqResult = seq.match(regex);
  if (!seqResult || seqResult.length ==0) return false;
  return (3===seqResult[0].length);

}

export const hasFileExtension = (fileName, extension) =>{
  if (!existsString(fileName)) return false;
  fileName = fileName.toUpperCase();
  //Check the file extension
  fileName = fileName.substring(fileName.lastIndexOf('.'));
  return fileName === extension.toUpperCase();
}

