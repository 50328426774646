import {authRefreshTokenIfExpired} from "./auth";
import {
    BATCH_ADD_FILE,
    BATCH_ADD_MODEL_FILE,
    BATCH_CHECK_RELOAD,
    BATCH_CHECK_RELOAD_COMPLETE,
    BATCH_CREATE,
    BATCH_CREATE_COMPLETE,
    BATCH_CREATE_ERROR, BATCH_HASH_FILE_START,
    BATCH_REMOVE_FILE,
    BATCH_REMOVE_MODEL_FILE,
    BATCH_REPEAT,
    BATCH_RESET,
    BATCH_START_UPLOAD,
    BATCH_UPDATE,
    BATCH_UPLOAD_CHUNK,
    BATCH_UPLOAD_CHUNK_COMPLETE,
    BATCH_UPLOAD_CHUNK_ERROR,
    BATCH_UPLOAD_COMPLETE,
    BATCH_UPLOAD_FILE_START,
    BATCHES_LOAD_COMPLETE,
    BATCHES_LOAD_ERROR,
    BATCHES_LOAD_REQUESTED
} from "../actionTypes";
import {SERVER_URL} from "../config";
import {getExtension, getSequence} from "../parseUtil";

import SparkMD5 from "spark-md5";

const FILE_CHUNK_SIZE = 250 * 1024;
const FILE_SIMULTANEOUS_UPLOADS = 4;

export const batchRepeat= (batch)  => async (dispatch, getState) => {
    dispatch({type:BATCH_REPEAT, value:batch})
}

export const checkBatchReload = ()  => async (dispatch, getState) => {
    await dispatch(authRefreshTokenIfExpired());

    if (!getState().batch.checkingReload) {
        console.log("[checkBatchReload] Checking batchReload");
        try {
            await dispatch({type: BATCH_CHECK_RELOAD});
            const url = SERVER_URL + '/api/batch/new';
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getState().auth.accessCode,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                await dispatch({type: BATCH_CHECK_RELOAD_COMPLETE, value: data});
                if (data.value) {
                    dispatch(batchLoad());
                }
            } else {
                //console.error('[batchLoad] Server turned response code: ' + response.status);
                await dispatch({type: BATCH_CHECK_RELOAD_COMPLETE});
            }
        } catch (ex) {
            console.error('[batchLoad] error loading batch data', ex);
            await dispatch({type: BATCH_CHECK_RELOAD_COMPLETE});
        }
    }
}

export const batchLoad = ()  => async (dispatch, getState) => {
        await dispatch(authRefreshTokenIfExpired());

        try {
            await dispatch({type: BATCHES_LOAD_REQUESTED});
            const url = SERVER_URL + '/api/batch';
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getState().auth.accessCode,
                     'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                await dispatch({type: BATCHES_LOAD_COMPLETE, value:data});
            } else {
                console.error('[batchLoad] Server turned response code: ' + response.status);
                await dispatch({type: BATCHES_LOAD_ERROR});
            }
        } catch (ex) {
            console.error('[batchLoad] error loading batch data', ex);
            await dispatch({type: BATCHES_LOAD_ERROR});
        }
}


export const batchDelete = (batchId)  => async (dispatch, getState) => {
    try {
        const url = SERVER_URL + '/api/batch/' + batchId;
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.accessCode,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            dispatch(batchLoad());
        } else {
            console.error('[batchDelete] Server turned response code: ' + response.status);
        }
    } catch (ex) {
        console.error('[batchDelete] error deleting batch data', ex);
    }
}


export const batchReset = ()  => async (dispatch) => {
    await dispatch({type:BATCH_RESET});
}

export const batchUpdate = (value) => async (dispatch) => {
    await dispatch({type:BATCH_UPDATE, value:value})
}


export const batchAddFile = (file) => async (dispatch) => {
    await dispatch({type:BATCH_ADD_FILE, value:file})
}

export const batchRemoveFile = (file) => async (dispatch) => {
    await dispatch({type:BATCH_REMOVE_FILE, value:file})
}

export const batchAddModelFile = (file) => async (dispatch) => {
    await dispatch({type:BATCH_ADD_MODEL_FILE, value:file})
}

export const batchRemoveModelFile = (file) => async (dispatch) => {
    await dispatch({type:BATCH_REMOVE_MODEL_FILE, value:file})
}

export const batchCreate = () => async (dispatch, getState) =>{
    await dispatch(authRefreshTokenIfExpired());

    const batchData = getState().batch.data;

    if (batchData.description === undefined ||
        batchData.description === null ||
        batchData.description.trim().length ===0){
        //Use the name of the first file. Strip the extension and channel name.
        if (!batchData.isRepeat) {
            batchData.description = getState().batch.files[0].name;
        } else {
            batchData.description = getState().batch.files[0].fileName;
        }
        batchData.description = batchData.description.substring(0, batchData.description.lastIndexOf('.')); //Remove Extension


        if (batchData.description.lastIndexOf('_CH0') > 0) {
            batchData.description = batchData.description.substring(0, batchData.description.lastIndexOf('_CH0')); //Remove Channel Id
        }
    }

    try {
        await dispatch({type: BATCH_CREATE});
        const url = SERVER_URL + '/api/batch';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.accessCode,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(batchData)
        });
        if (response.status === 200) {
            const data = await response.json();
            await dispatch({type: BATCH_CREATE_COMPLETE, value:data});
        } else {
            console.error('[batchCreate] Server turned response code: ' + response.status);
            await dispatch({type: BATCH_CREATE_ERROR});
        }
    } catch (ex) {
        console.error('[batchCreate] error creating batch data', ex);
        await dispatch({type: BATCH_CREATE_ERROR});
    }
}

export const batchStartUpload  = () => async (dispatch, getState) =>{
    await dispatch(authRefreshTokenIfExpired());
    await dispatch(batchCreate());
    await dispatch({type: BATCH_START_UPLOAD});
}



export const calcMd5Hash = (file, bufferSize) => {
    return new Promise((hash) => {

        const fileReader = new FileReader();
        const fileSlicer = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        const hashAlgorithm = new SparkMD5();
        const totalParts = Math.ceil(file.size / bufferSize);
        let currentPart = 0;
        const startTime = new Date().getTime();

        fileReader.onload = function (e) {
            currentPart += 1;
            const buffer = e.target.result;
            hashAlgorithm.appendBinary(buffer);
            if (currentPart < totalParts) {
                processNextPart();
                return;
            }

            hash({
                error: false,
                hashResult: hashAlgorithm.end(),
                duration: new Date().getTime() - startTime
            });
        };

        fileReader.onerror = function (e) {
            hash({error: true})
        };

        function processNextPart() {
            const start = currentPart * bufferSize;
            const end = Math.min(start + bufferSize, file.size);
            fileReader.readAsBinaryString(fileSlicer.call(file, start, end));
        }

        processNextPart();

    });
}

//Global Parameter


export const batchUploadTask = () => async (dispatch, getState) =>{

    const batch = getState().batch;
    const batchId = batch.data.id;

    //Check to see
    if (batch.uploading){
        if (batch.uploadCompleting){
            await dispatch({type:BATCH_UPLOAD_COMPLETE});
            const url = `${SERVER_URL}/api/batch/${batchId}/complete`;
            await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getState().auth.accessCode,
                    'Accept': 'application/json',
                },
            });

        } else {

            //Check timeout, state, next file etc.
            let uploadCount = batch.uploadCount;
            for (let file of batch.uploadFileList) {
                switch (file.status) {
                    case 'QUEUED': {
                        if (uploadCount < FILE_SIMULTANEOUS_UPLOADS) {
                            console.log(`[batchUploadTask] Starting hash of ${file.name}`);
                            dispatch({type: BATCH_HASH_FILE_START, value: file});
                            uploadCount++; //Local counter to fast-track state changes
                            //Calculate MD5 Hash
                            calcMd5Hash(file.fileObject, FILE_CHUNK_SIZE).then((hash) => {
                                console.error("HASH IS: ", hash);
                                file.md5 = hash.hashResult;
                                console.log(`[batchUploadTask] Starting upload of ${file.name} hash:${file.md5}`);
                                dispatch({type: BATCH_UPLOAD_FILE_START, value: file});
                            });

                        }
                        break;
                    }
                    case 'HASHED': {
                        break;
                    }
                    case 'UPLOADING': {
                        //Check chunk status
                        if (file.chunkStatus === 'IDLE') {
                            //send next chunk
                            //console.log(`[batchUploadTask][${new Date().getTime()}] Sending Chunk of ${file.name}`);
                            await dispatch(authRefreshTokenIfExpired());
                            dispatch(uploadFileChunk(batch, file));
                        }
                        break;
                    }
                    case 'COMPLETE': {
                        break;
                    }

                }

            }

        }
    }
}


const uploadFileChunk =  (batch, file) => async (dispatch, getState) =>{

    const startTime = new Date().getTime();
    const start = file.bytesUploaded;
    const batchId = getState().batch.data.id;

    try {
        const chunk = file.fileObject.slice(start, start + FILE_CHUNK_SIZE);
        const fileType = batch.data.fileType;
        const extension = getExtension(fileType);
        const channel = getSequence(fileType, extension, file.name);

        //console.error(`fileType: ${fileType} extension: ${extension} channel: ${channel}`);

        const formData = new FormData();
        formData.append('name', file.name);
        formData.append('model', file.model);
        formData.append('md5', file.md5);
        formData.append('start', start);
        formData.append('chunkSize', chunk.size);
        formData.append('totalFileSize', file.fileObject.size);
        formData.append('channel', channel);
        formData.append('file', chunk);


        await dispatch({type: BATCH_UPLOAD_CHUNK, name: file.name});
        //console.log(`[uploadFileChunk] Uploading file chunk: ${file.name} start:${start} rangeEnd:${rangeEnd}`);
        const url = `${SERVER_URL}/api/batch/${batchId}/file`;


        const startTime = new Date().getTime();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.accessCode,
                'Accept': 'application/json',
            },
            body:formData
        });
        if (response.status === 200) {

            const data = await response.json();

            if (data.value) {
                await dispatch({
                    type: BATCH_UPLOAD_CHUNK_COMPLETE,
                    name: file.name,
                    start: start,
                    bytesUploaded: (start + chunk.size),
                });

            } else {
                console.error(`[uploadFileChunk][${file.name}] Server indicated upload failed. Scheduling retry`);
                await dispatch({type: BATCH_UPLOAD_CHUNK_ERROR, name: file.name});
            }
        } else {
            console.error(`[uploadFileChunk][${file.name}] Server turned response code: ${response.status}. Scheduling retry`);
            await dispatch({type: BATCH_UPLOAD_CHUNK_ERROR, name: file.name});
        }
    } catch (ex) {
        console.error('[uploadFileChunk][${file.name}] error uploading chunk. Attempting retry', ex);
        await dispatch({type: BATCH_UPLOAD_CHUNK_ERROR, name: file.name});
    }
}


