import React from 'react'
import {ScrollView, StyleSheet, Text, View} from "react-native";
import baseStyle from "../../../baseStyle";
import InProgressFileEntry from "./InProgressFileEntry";

export default function InProgressBatchList(props) {

    const {batches} = props;

    return (
        <View style={style.container}>
            <View style={baseStyle.columnHeaderRow}>
                <Text style={baseStyle.columnHeaderText}>Name</Text>
                <Text style={baseStyle.columnHeaderText}>Date Added</Text>
                <Text style={baseStyle.columnHeaderText}>Status</Text>
                <Text style={baseStyle.columnHeaderText}>&nbsp;</Text>
            </View>
            <ScrollView style={baseStyle.batchListScrollView}>

                {batches.map((batch, index)=>{

                    return <InProgressFileEntry
                        key={index}
                        batch={batch}
                        onInfo={props.onInfo}
                        onDelete={props.onDelete}/>
                })}
            </ScrollView>
        </View>

    )
}



const style = StyleSheet.create({
    container:{
        flex:1,
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems:'flex-start',
        alignSelf: 'stretch',
    }
});
