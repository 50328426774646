import React, {useRef} from 'react'
import {SafeAreaView, ScrollView, StyleSheet, Text, View} from 'react-native'
import {useDispatch, useSelector} from "react-redux";
import {useNavigation} from "@react-navigation/core";
import baseStyle, {COLOR_SECONDARY} from "../../baseStyle";
import BatchEntryStatus from "./components/BatchEntryStatus";
import {TextInput} from "react-native-paper";
import {batchCreate, batchUpdate} from "../../store/actions";
import FileTypeSelection from "./components/FileTypeSelection";
import NumChannelsSelection from "./components/NumChannelsSelection";
import NumericInput from "./components/NumericInput";
import {Button} from "react-native-elements";
import SampleRateSelection from "./components/SampleRateSelection";
import {paperTheme} from "../../paperTheme";
import FrameRateSelection from "./components/FrameRateSelection";
import AdaptiveRateSelection from "./components/AdaptiveRateSelection";
import AdaptiveRateStepSelection from "./components/AdaptiveRateStepSelection";
import BatchValidationErrorDialog from "./components/BatchValidationErrorDialog";


export default function CreateBatchScreen() {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const errorRef = useRef();


    const {
        isRepeat,
    } = useSelector((state) => state.batch.data);

    const {
        description,
        showAdvanced,
        sampleRate,
        fileType,
        numChannels,
        frameSizeLow,
        frameSizeHi,
        adaptiveRateLow,
        adaptiveRateHi,
        adaptiveRateStep,
        modelStart,
        modelEnd,
        processStart,
        processEnd,
        gateLevelGF,
        midModelLengthGF,
        utteranceLength,
        gateLevelD,
        midModelLengthD,
        lowPass,
        highPass,
        arrayWidth
    } = useSelector((state) => state.batch.data);

    const updateProcessTimingProperties = (newProcStart, newProcEnd) => {
        //3 decimals

        let newStart = parseFloat(newProcStart);
        let newEnd = parseFloat(newProcEnd);

        let isMissing = false;

        let changed = false
        if (isNaN(newStart)) {changed=true; newStart = ''; isMissing = true;};
        if (isNaN(newEnd)) { changed=true; newEnd = ''; isMissing = true;};
        if (!isMissing && (newEnd < newStart)) { newProcEnd = newProcStart};

        if (changed) {
            dispatch(batchUpdate({
                processStart: newStart.toString(),
                processEnd: newEnd.toString(),
            }));
        } else {
            dispatch(batchUpdate({
                processStart: newProcStart,
                processEnd: newProcEnd
            }));
        }

    }
    const updateModelTimingProperties = (newModelStart, newModelEnd) => {



        let newStart = parseFloat(newModelStart);
        let newEnd = parseFloat(newModelEnd);
        let newGF = 2.0;
        let newD = 5.0;
        let changed = false;

        let isMissing = false;
        if (isNaN(newStart)) { changed=true; newStart = ''; isMissing = true;};
        if (isNaN(newEnd)) { changed=true; newEnd = ''; isMissing = true;};

        if (!isMissing) {
            // newEnd = parseFloat(newEnd);
            // newStart = parseFloat(newStart);
            if (newEnd < newStart) {
                newModelEnd = newModelStart;
                newEnd = newStart;
            }

            const duration = newEnd - newStart;

            if (duration > 0.001) {
                let newMM = duration * (2 / 3);
                if (isNaN(newMM) || newMM < 0) newMM = 0;
                newMM = parseFloat(newMM.toFixed(2));
                newGF = newMM;
                newD = newMM;
            }

        }

        if (changed) {
            dispatch(batchUpdate({
                modelStart: newStart.toString(),
                modelEnd: newEnd.toString(),
                midModelLengthGF: newGF.toString(),
                midModelLengthD: newD.toString()
            }));
        } else {
            dispatch(batchUpdate({
                modelStart: newModelStart,
                modelEnd: newModelEnd,
                midModelLengthGF: newGF.toString(),
                midModelLengthD: newD.toString()
            }));
        }
    }

    const isMissing = (s) => {
        return (s===undefined || s===null || s.toString().trim().length == 0);
    }
    const isValid  = () =>{
        const missingFields = [];
        if (isMissing(modelStart)) missingFields.push('Model Start is missing.');
        if (isMissing(modelEnd)) missingFields.push('Model End is missing.');
        if (isMissing(processStart)) missingFields.push('Process Start is missing.');
        if (isMissing(processEnd)) missingFields.push('Process End is missing.');
        if (isMissing(gateLevelGF)) missingFields.push('Gate Level GF is missing.')
        if (isMissing(midModelLengthGF)) missingFields.push('Minimum Model Length GF is missing.')
        if (isMissing(utteranceLength)) missingFields.push('Utterance Length is missing.')
        if (isMissing(gateLevelD)) missingFields.push('Gate Level D is missing.')
        if (isMissing(midModelLengthD)) missingFields.push('Minimum Model Length D is missing.')
        if (isMissing(lowPass)) missingFields.push('Low Pass is missing.')
        if (isMissing(highPass)) missingFields.push('High Pass is missing.')
        if (isMissing(arrayWidth)) missingFields.push('Array width is missing.')
        if (missingFields.length > 0){
            errorRef.current.open(missingFields);
            return false;
        }

        return true;

    }

    return (

        <ScrollView>
            <SafeAreaView style={[baseStyle.container, {alignItems: 'center'}]}>
                <BatchEntryStatus isRepeat={isRepeat} step={0}/>
                <BatchValidationErrorDialog ref={errorRef} />

                <View style={baseStyle.raisedCard}>

                    <Text style={baseStyle.titleStyle}>Please enter the processing parameters below</Text>

                    <View style={baseStyle.subcaptionContainerStyle}>
                        <TextInput
                            theme={paperTheme}
                            activeOutlineColor={COLOR_SECONDARY}
                            style={baseStyle.textFieldStyle}
                            label='Description'
                            mode='outlined'
                            value={description}
                            onChangeText={(value) => dispatch(batchUpdate({description: value}))}
                        />
                        <Text style={[baseStyle.subcaptionText, {marginLeft: 44, marginTop:8, marginBottom:32, maxWidth: '100%'}]}>
                            Short, descriptive title of the job to be used as the name of the ZIP file with job results. If left blank, the name of the first source file will be used.
                        </Text>
                    </View>



                    <View style={style.row}>
                        {/*<NumericInput*/}
                        {/*    style={[{margin:8}]}*/}
                        {/*    label="Sample Rate"*/}
                        {/*    value={sampleRate}*/}
                        {/*    caption={'sample rate of data in Hz'}*/}
                        {/*    onChangeText={(value) => dispatch(batchUpdate({sampleRate: value}))}*/}
                        {/*/>*/}

                        <FileTypeSelection style={[{margin:8}]} value={fileType} onSelected={(value)=>dispatch(batchUpdate({fileType: value}))}/>
                        <SampleRateSelection style={[{margin:8}]} value={sampleRate}   fileType={fileType} onSelected={(value)=>dispatch(batchUpdate({sampleRate: value}))}/>
                        <NumChannelsSelection style={[{margin:8}]} value={numChannels} fileType={fileType} onSelected={(value)=>dispatch(batchUpdate({numChannels: value}))}/>

                    </View>

                    <Text style={[baseStyle.subheadStyle, {marginTop:24, marginBottom:0}]}>Adaptive Filter Properties</Text>

                    <View style={style.row}>
                        <FrameRateSelection
                            value={frameSizeLow}
                            style={[{margin:8}]}
                            minRate={"128"}
                            label="Frame Size Low"
                            caption={'Minimum processing frame size in samples'}
                            onSelected={(value) => dispatch(batchUpdate({frameSizeLow: value}))}
                        />


                        <FrameRateSelection
                            value={frameSizeHi}
                            minRate={frameSizeLow}
                            style={[{margin:8}]}
                            label="Frame Size High"
                            caption={'Maximum processing frame size in samples. Set equal\nto minimum frame size if only the single size is desired.'}
                            onSelected={(value) => dispatch(batchUpdate({frameSizeHi: value}))}
                        />
                    </View>

                    <View style={[style.row,{marginTop:16}]}>
                        <AdaptiveRateSelection
                            style={[{margin:8}]}
                            label="Adaptive Rate Low"
                            value={adaptiveRateLow}
                            minRate={'1'}
                            caption={'Minimum filter adaptation rate. Higher settings attack more aggressively.'}
                            onSelected={(value) => dispatch(batchUpdate({adaptiveRateLow: value}))}
                        />


                        <AdaptiveRateSelection
                            style={[{margin:8}]}
                            label="Adaptive Rate High"
                            value={adaptiveRateHi}
                            minRate={adaptiveRateLow}
                            caption={'Maximum filter adaptation rate. Set equal to minimum adaptation rate if only the single rate is desired.'}
                            onSelected={(value) => dispatch(batchUpdate({adaptiveRateHi: value}))}
                        />


                        <AdaptiveRateStepSelection
                            style={[{margin:8}]}
                            label="Adaptive Rate Step"
                            value={adaptiveRateStep}
                            minRate={'1'}
                            caption={'Adapt rate increment size.'}
                            onSelected={(value) => dispatch(batchUpdate({adaptiveRateStep: value}))}
                        />


                    </View>


                    <Text style={[baseStyle.subheadStyle, {marginTop:24, marginBottom:0}]}>Model Timing Properties</Text>
                    <Text style={[baseStyle.instructions, {padding:0, paddingBottom:0}]}>Set start and end times to zero to use the entire file for modeling.</Text>
                    <View style={style.row}>
                        <NumericInput
                            style={[{margin:8}]}
                            decimal={3}
                            label="Model Start"
                            value={modelStart}
                            caption={'Modeling segment start time in seconds.'}
                            onChangeText={(value) => updateModelTimingProperties(value, modelEnd)}

                            right={<TextInput.Affix text="sec" />}
                        />
                        <NumericInput
                            style={[{margin:8}]}
                            decimal={3}
                            label="Model End"
                            value={modelEnd}
                            caption={'Modeling segment end time in seconds.'}
                            onChangeText={(value) => updateModelTimingProperties(modelStart, value)}
                            right={<TextInput.Affix text="sec" />}
                        />
                    </View>


                    <Text style={[baseStyle.subheadStyle, {marginTop:24, marginBottom:0}]}>Recording Timing Properties</Text>
                    <Text style={[baseStyle.instructions, {padding:0, paddingBottom:0}]}>Set start and end to zero to process everything</Text>
                    <View style={style.row}>
                        <NumericInput
                            style={[{margin:8}]}
                            decimal={3}
                            label="Process Start"
                            value={processStart}
                            caption={'Input recording processing start time in seconds.'}
                            onChangeText={(value) => updateProcessTimingProperties(value, processEnd)}
                            right={<TextInput.Affix text="sec" />}
                        />


                        <NumericInput
                            style={[{margin:8}]}
                            decimal={3}
                            label="Process End"
                            value={processEnd}
                            caption={'Input recording processing end time in seconds.'}
                            onChangeText={(value) => updateProcessTimingProperties(processStart, value)}
                            right={<TextInput.Affix text="sec" />}
                        />
                    </View>

                    {!showAdvanced &&
                        <Button
                            style={[{margin: 16}]}
                            title="SHOW ADVANCED"
                            titleStyle={baseStyle.clearButtonText}
                            type='clear'
                            icon={{
                                name: 'caret-down',
                                type: 'font-awesome',
                                size: 15,
                                color: COLOR_SECONDARY
                            }}
                            iconRight
                            onPress={()=>dispatch(batchUpdate({showAdvanced: true}))}
                        />
                    }

                    {showAdvanced &&
                        <View>



                            <Text style={[baseStyle.subheadStyle, {marginTop:24, marginBottom:0}]}>Source Location Spatial Model Estimator Parameters</Text>
                            <View style={style.row}>
                                <NumericInput
                                    style={[{margin:8}]}
                                    label="Gate Level GF"
                                    value={gateLevelGF}
                                    caption={'Controls the threshold under which softer sounds are excluded from the desired source location’s spatial model. Higher settings exclude more soft sounds.'}
                                    onChangeText={(value) => dispatch(batchUpdate({gateLevelGF: value}))}
                                    right={<TextInput.Affix text="%" />}
                                />
                                <NumericInput
                                    style={[{margin:8}]}
                                    decimal={3}
                                    label="Minimum Model Length GF"
                                    value={midModelLengthGF}
                                    caption={'Process at least this much modeling data in seconds. Value should be less than length of modeling segment covered by start and end times entered above.'}
                                    onChangeText={(value) => dispatch(batchUpdate({midModelLengthGF: value}))}
                                    right={<TextInput.Affix text="sec" />}
                                />
                            </View>

                            <Text style={[baseStyle.subheadStyle, {marginTop:24, marginBottom:0}]}>DELAY ESTIMATOR PARAMETERS</Text>
                            <View style={style.row}>
                                <NumericInput
                                    style={[{margin:8}]}
                                    label="Utterance Length"
                                    value={utteranceLength}
                                    caption={'Minimum expected length in ms of useful modeling sound.'}
                                    onChangeText={(value) => dispatch(batchUpdate({utteranceLength: value}))}
                                    right={<TextInput.Affix text="ms" />}
                                />
                                <NumericInput
                                    style={[{margin:8}]}
                                    label="Gate Level D"
                                    value={gateLevelD}
                                    caption={'Controls the threshold under which softer sounds are excluded from the desired source location delay estimator. Higher settings exclude more soft sounds.'}
                                    onChangeText={(value) => dispatch(batchUpdate({gateLevelD: value}))}
                                    right={<TextInput.Affix text="%" />}
                                />
                                <NumericInput
                                    style={[{margin:8}]}
                                    decimal={3}
                                    label="Min-Model Length D"
                                    value={midModelLengthD}
                                    caption={'Process at least this much modeling data in seconds. Value should be less than length of modeling segment covered by start and end times entered above.'}
                                    onChangeText={(value) => dispatch(batchUpdate({midModelLengthD: value}))}
                                    right={<TextInput.Affix text="sec" />}
                                />
                            </View>

                            <View style={[style.row, {marginTop:16}]}>

                                <NumericInput
                                    style={[{margin:8}]}
                                    label="High Pass"
                                    value={highPass}
                                    caption={'Attenuate below this frequency (in Hz) for delay estimator.'}
                                    onChangeText={(value) => dispatch(batchUpdate({highPass: value}))}
                                    right={<TextInput.Affix text="Hz" />}
                                />
                                <NumericInput
                                    style={[{margin:8}]}
                                    label="Low Pass"
                                    value={lowPass}
                                    caption={'Attenuate above this frequency (in Hz) for delay estimator.'}
                                    onChangeText={(value) => {
                                        console.error(">>>>V:", value);
                                        dispatch(batchUpdate({lowPass: value}))}}
                                    right={<TextInput.Affix text="Hz" />}
                                />
                            </View>


                            <View style={[style.row, {marginTop:16}]}>
                                <NumericInput
                                    style={[{margin:8}]}
                                    label="Array Width"
                                    value={arrayWidth}
                                    caption={'Controls maximum search space. For a planar array, this can be half the actual array width if the desired source location is in the far field and < 30° off-axis. For a solid spherical array, multiply by π/2  if the desired source location is in the near field.'}
                                    onChangeText={(value) => dispatch(batchUpdate({arrayWidth: value}))}
                                    right={<TextInput.Affix text="cm" />}
                                />
                            </View>

                        </View>
                    }


                    <View style={baseStyle.buttonContainer}>
                        <Button
                            buttonStyle={baseStyle.raisedButton}
                            titleStyle={baseStyle.raisedButtonText}
                            title={'CANCEL'}
                            onPress={async ()=>{
                                navigation.navigate('Home')
                            }}/>

                        <Button
                            buttonStyle={baseStyle.raisedButton}
                            titleStyle={baseStyle.raisedButtonText}
                            title={'NEXT'}

                            onPress={async ()=>{
                                if (isValid()) {
                                    if (isRepeat) {
                                        await dispatch(batchCreate());
                                        navigation.navigate('UploadComplete');
                                    } else {
                                        navigation.navigate('SelectFiles')
                                    }
                                }
                            }}/>



                    </View>

                </View>

            </SafeAreaView>
        </ScrollView>

    )
}

const style = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent:"space-around",
        width:'100%'
    },

});
