import color from 'color';
import {black, pinkA400, white} from "react-native-paper/src/styles/colors";
import configureFonts from "react-native-paper/src/styles/fonts";

export const paperTheme = {
    mode:'exact',
    dark: false,
    roundness: 4,
    colors: {
        primary: '#6200ee',
        accent: '#03dac4',
        background: '#f6f6f6',
        surface: white,
        error: '#B00020',
        text: black,
        onSurface: '#000000',
        disabled: color(black).alpha(0.26).rgb().string(),
        placeholder: color(black).alpha(0.54).rgb().string(),
        backdrop: color(black).alpha(0.5).rgb().string(),
        notification: pinkA400,
    },
    fonts: configureFonts(),
    animation: {
        scale: 1.0,
    },
};
