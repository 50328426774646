import {StatusBar} from 'expo-status-bar';
import React, {useEffect, useRef, useState} from 'react';
import {AppState, ActivityIndicator, View, Text, Platform} from 'react-native';
import {enableScreens} from "react-native-screens";
import {configureStore} from "./src/store/store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import { Provider as PaperProvider } from 'react-native-paper';
import {COLOR_PRIMARY, COLOR_PRIMARY_DARK} from "./src/baseStyle";
import {NavigationContainer} from "@react-navigation/native";
import {GestureHandlerRootView} from "react-native-gesture-handler";
import RootNavigator from "./src/navigators/RootNavigator";
import * as PropTypes from "prop-types";
import {appStartTimerPumpInterval, appStopTimerPumpInterval, batchReset, usersReset} from "./src/store/actions";

//performance boost
enableScreens(true);

//Redux configuration
console.log('[App] Configuring Store');
let store = configureStore({})
console.log('[App] Configuring Persistor');
const persistor = persistStore(store)
console.log('[App] Done Configuring Store');

const AppLoading = () => {
    return (
        <View>
            <ActivityIndicator size="large" color={COLOR_PRIMARY}/>
        </View>
    )
}


PaperProvider.propTypes = {children: PropTypes.node};
export default function App() {

    //Monitor status of app state
    const appState = useRef(AppState.currentState);
    const [appStateVisible, setAppStateVisible] = useState(appState.current);
    useEffect(() => {

        store.dispatch(batchReset());
        store.dispatch(usersReset());
        store.dispatch(appStartTimerPumpInterval());

        AppState.addEventListener('change', _handleAppStateChange);
        return () => {
            AppState.removeEventListener('change', _handleAppStateChange);
        };
    }, []);

    useEffect(async () => {
        console.log(`[App] appStateVisible: ${appStateVisible}`);
        if (appStateVisible) {
            // console.log('[App] Starting Up Timer Pump')
            // store.dispatch(batchReset());
            // await store.dispatch(appStartTimerPumpInterval());
        } else {
            // console.log('[App] Shutting Down Timer Pump')
            // await store.dispatch(appStopTimerPumpInterval());
        }
    }, [appStateVisible]);
    //
    const _handleAppStateChange = (nextAppState) => {
        let visible = false;
        if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
            console.log('App has come to the foreground!');
            visible = true;
        }
        appState.current = nextAppState;
        setAppStateVisible(visible);
        console.log('AppState', appState.current);
    };

    return (
        <NavigationContainer>
            <Provider store={store}>

                    <PersistGate loading={<AppLoading/>} persistor={persistor}>
                        <StatusBar backgroundColor={COLOR_PRIMARY_DARK}/>
                        <GestureHandlerRootView style={{flex: 1}}>
                            <PaperProvider>
                                <RootNavigator/>
                            </PaperProvider>
                        </GestureHandlerRootView>
                    </PersistGate>

            </Provider>
        </NavigationContainer>
    );
}
